import React, { useState, useEffect } from 'react'

export const useExpireDate = () => {
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [timeLeft, setTimeLeft] = useState<number>(endDate.getTime() - Date.now())
  const [isExpired, setIsExpired] = useState<boolean>(false)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    const Update = () => {
      const timeLeft = endDate.getTime() - Date.now()
      setTimeLeft(timeLeft)
      if (timeLeft <= 0) {
        setIsExpired(true)

        if (interval) {
          clearInterval(interval)
        }
      }
    }

    interval = setInterval(Update, 1000)

    Update()

    return () => {
      clearInterval(interval)
    }
  }, [endDate])

  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor((timeLeft / 1000) % 60)
    .toString()
    .padStart(2, '0')

  return { timeLeft, setEndDate, isExpired, formatted: `${hours}:${minutes}:${seconds}` }
}
