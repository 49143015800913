import { useEffect, useMemo, useState } from 'react'
import { useCustomerContext } from '~/context/customer-context'
import { axiosRequests } from '~/utils/axios'
import { SwymList, SwymSFL } from './types'

// Wishlist hook
export const useSwymlists = (): IUseSwymlists => {
  const AxiosFunctions = axiosRequests()
  const { customer, fetchCustomer } = useCustomerContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [hasNoWishlists, setHasNoWishlists] = useState<boolean>(false)
  const [hasNoSflWishlists, setHasNoSflWishlists] = useState<boolean>(false)
  const [listsArray, setListsArray] = useState<Array<SwymList> | null>(null)
  const [sflLists, setSflLists] = useState<SwymSFL | null>(null)
  const [regid, setRegid] = useState('')
  const [sessionid, setSessionid] = useState('')
  const [responseData, setResponseData] = useState<any>()
  const [defaultList, setDefaultList] = useState<SwymList | null>(null)

  useEffect(() => {
    if (!defaultList && listsArray) {
      setDefaultList(listsArray.find(list => list.lname === 'default') || null)
    }
  }, [listsArray])

  const genRegid = async () => {
    if (!customer?.email) return

    await AxiosFunctions.genRegId(customer?.email)
      .then(function (response) {
        setRegid(response.data.regid)
        setSessionid(response.data.sessionid)
        setResponseData(response)
      })
      .catch(function () {
        // console.log(error);
      })
  }

  const createWishlists = async (listName: string, Response: any) => {
    await AxiosFunctions.createWishlists(listName, Response)
      .then(function () {
        setTimeout(() => {
          genRegid()
        }, 1000)
      })
      .catch(function () {
        // console.log(error);
      })
  }

  const createSfl = async () => {
    await AxiosFunctions.createSwymSfl(sessionid, regid)
      .then(function (res) {
        setSflLists(res.data)
      })
      .catch(function () {
        // console.log(error);
      })
  }

  const getSwymlists = async () => {
    try {
      await AxiosFunctions.getSwymlists(sessionid, regid).then(function (response) {
        if (response.data.length >= 1) {
          try {
            const defaultObject = response.data.find(obj => obj.lname === 'default')
            const otherObjects = response.data.filter(obj => obj.lname !== 'default')
            const reorderedArray = [defaultObject, ...otherObjects] as Array<SwymList>
            setListsArray(reorderedArray)
          } catch (error) {
            // console.log(error);
          }
        } else {
          setListsArray(response.data)

          return
        }
        if (response.data.length < 1) {
          setHasNoWishlists(true)
          const hasDefaultList = response.data.some(list => list.lname === 'default')
          if (!hasDefaultList) {
            createWishlists('default', responseData)
          }
          return
        } else {
          setHasNoWishlists(false)
        }

        const hasDefaultList = response.data.some(list => list.lname === 'default')

        if (!hasDefaultList) {
          createWishlists('default', responseData)
        }
      })
    } catch (error) {
      // console.log(error);
    }
  }
  const getSfl = async () => {
    try {
      await AxiosFunctions.getSwymlists(sessionid, regid, true).then(function (response) {
        try {
          const slfList = response.data

          if (!slfList?.list.lname) {
            createSfl()

            return
          } else {
            setSflLists(slfList)

            return
          }
        } catch (error) {
          // console.log(error);
        }
      })
    } catch (error) {
      // console.log(error);
    }
  }

  const addToWishlist = async ({ productVariantId, shopifyId, product, listId }: any) => {
    if (customer) {
      const targetList = listId ?? defaultList?.lid
      setLoading(true)
      await AxiosFunctions.addToWishlist(
        productVariantId,
        shopifyId,
        sessionid,
        regid,
        targetList,
        product.handle
      ).then(function (response) {
        if (!listId) {
          setDefaultList(current => ({
            ...current,
            listcontents: [...current.listcontents, response.data.a[0]],
          }))
        } else {
          setListsArray(current =>
            current.map(list => {
              if (list.lid === targetList) {
                return {
                  ...list,
                  listcontents: [...list.listcontents, response.data.a[0]],
                }
              } else {
                return list
              }
            })
          )
        }
        setLoading(false)
      })
    }
  }

  const addToSfl = async ({ productVariantId, shopifyId, product }: any) => {
    if (customer && sflLists) {
      setLoading(true)
      await AxiosFunctions.addToSfl(
        productVariantId,
        shopifyId,
        sessionid,
        regid,
        sflLists?.list.lid,
        product.handle
      ).then(function (response) {
        setSflLists(current => ({
          ...current,
          items: [...current.items, response.data.itemsadded[0]],
        }))

        setLoading(false)
      })
    }
  }

  const removeFromWishlist = async ({ productVariantId, shopifyId, product, listId }: any) => {
    if (customer) {
      const targetList = listId ?? defaultList?.lid
      setLoading(true)
      await AxiosFunctions.removeFromWishlist(
        productVariantId,
        shopifyId,
        sessionid,
        regid,
        targetList,
        product.handle
      ).then(function (response) {
        if (listId) {
          setListsArray(current =>
            current.map(list => {
              if (list.lid === listId) {
                return {
                  ...list,
                  listcontents: list.listcontents.filter(
                    item => item.epi !== response.data.d[0].epi
                  ),
                }
              } else {
                return list
              }
            })
          )
        } else {
          setDefaultList(current => ({
            ...current,
            listcontents: current.listcontents.filter(item => item.epi !== response.data.d[0].epi),
          }))
        }
      })

      setLoading(false)
    }
  }

  const removeFromSfl = async ({ productVariantId, shopifyId, product }: any) => {
    if (customer && sflLists) {
      setLoading(true)
      await AxiosFunctions.removeFromSfl(
        productVariantId,
        shopifyId,
        sessionid,
        regid,
        sflLists?.list.lid,
        product.handle
      ).then(function (response) {
        setSflLists(current => ({
          ...current,
          items: current.items.filter(item => item.epi !== productVariantId),
        }))

        setLoading(false)
      })
    }
  }

  useEffect(() => {
    genRegid()
  }, [customer])

  useEffect(() => {
    if (!!responseData && regid !== '' && sessionid !== '') {
      setLoading(true)
      getSwymlists()
      getSfl()
      setLoading(false)
    }
  }, [responseData])

  return {
    loading,
    hasNoWishlists,
    hasNoSflWishlists,
    listsArray,
    sflLists,
    defaultList,
    getSwymlists,
    createWishlists,
    addToWishlist,
    removeFromWishlist,
    addToSfl,
    removeFromSfl,
    regid,
    sessionid,
  }
}

export interface IUseSwymlists {
  regid: string
  sessionid: string
  listsArray: SwymList[] | null
  createWishlists: (listName: string, Response: any) => void
  getSwymlists: () => void
  hasNoWishlists: boolean
  hasNoSflWishlists: boolean
  loading: boolean
  sflLists: SwymSFL | null
  addToSfl: ({ productVariantId, shopifyId, product }: any) => void
  addToWishlist: ({ productVariantId, shopifyId, product }: any) => void
  removeFromSfl: ({ productVariantId, shopifyId, product }: any) => void
  removeFromWishlist: ({ productVariantId, shopifyId, product }: any) => void
  defaultList?: SwymList | null
}
