import React from 'react'
import { Location } from '@reach/router'
import { GatsbyLinkProps, Link } from 'gatsby'
import { ThemeUICSSObject } from 'theme-ui'

type LinkWithPrevUrlProps = GatsbyLinkProps<{}> & {
  state?: any
  ref?: string
  sx?: ThemeUICSSObject
}

const LinkWithPrevUrl = ({ children, state, sx, ...rest }: LinkWithPrevUrlProps) => {
  return (
    <Location>
      {({ location }) => {
        const pageTitle = typeof window !== 'undefined' ? window.document?.title : ''
        return (
          <Link
            {...rest}
            state={{
              prevUrl: location.href,
              prevTitle: pageTitle,
              prevPath: location.pathname,
              ...state,
            }}
          >
            {children}
          </Link>
        )
      }}
    </Location>
  )
}

export { LinkWithPrevUrl as Link }
