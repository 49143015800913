/** @jsx jsx */
import { Button as StyledButton, jsx } from 'theme-ui'
import { ArrowLong } from '~/components/icons/arrowLong'
import { Arrow } from '~/components/icons/arrow'
import { ButtonProps } from '~/@types/models'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'
import { Share } from '../icons/share'

export const Button = ({
  variant = 'primary',
  size = 'm',
  label,
  hasArrow = false,
  hasShare = false,
  sxProps = {},
  as = 'button',
  to = '#',
  onClick,
  arrowOnly = false,
  mobileBlock = false,
  ...rest
}: ButtonProps) => {
  const buttonStyles = {
    height: [37, 42],
    position: 'relative',
    textAlign: 'center',
    minWidth: [160, null, null, 272],
    width: mobileBlock ? ['100%', 'initial'] : 'initial',
    fontFamily: 'body',
    borderWidth: '1px',
    borderStyle: 'solid',
    px: 15,
    borderRadius: 0,
    opacity: rest.disabled ? 0.5 : 1,
    cursor: rest.disabled ? 'default' : 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    span: {
      mr: hasArrow || arrowOnly ? 15 : 0,
    },
    '> svg': {
      transition: 'transform 0.3s ease-in-out',
    },
    '&:hover': {
      opacity: rest.disabled ? 0.5 : 0.8,
      span: {
        mr: hasArrow ? (arrowOnly ? 90 : 28) : 0,
      },
      svg: {
        position: 'absolute',
        transform: arrowOnly
          ? ['translateX(80px)', null, null, 'translateX(90px)']
          : ['translateX(60px)', null, null, 'translateX(100px)'],
      },
    },
    ...(size === 'sm' && {
      height: 40,
      minWidth: ['initial', null, null, 'initial'],
      fontSize: [2],
    }),
  }

  return as === 'button' ? (
    <StyledButton
      {...rest}
      onClick={onClick}
      sx={{
        ...buttonStyles,
        ...sxProps,
      }}
      variant={variant}
    >
      <span>{label}</span>
      {hasArrow && (arrowOnly ? <ArrowLong /> : <Arrow />)}
      {hasShare && <Share color="#2b2b2b" width="24px" height="24px" />}
    </StyledButton>
  ) : (
    <Link
      onClick={onClick}
      to={to}
      sx={{
        ...buttonStyles,
        variant: `buttons.${variant}`,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        pointerEvents: rest.disabled ? 'none' : 'initial',
        ...sxProps,
      }}
      {...rest}
    >
      <span>{label}</span>
      {hasArrow && (arrowOnly ? <ArrowLong /> : <Arrow />)}
      {hasShare && <Share color="#2b2b2b" width="24px" height="24px" />}
    </Link>
  )
}
