import { gql } from 'graphql-request'

export const GET_MENU_IMAGES = gql`
  query Menu {
    menu: metaobject(id: "gid://shopify/Metaobject/5804982336") {
      type
      fields {
        value
        reference {
          ... on MediaImage {
            image {
              url
            }
          }
        }
        key
      }
    }
  }
`
