import { pipe, match, head } from 'ramda'

type NumberFormatOptions = {
  minimumFractionDigits: number
  maximumFractionDigits: number
}

const formatMoney = (amount: string | number, currency = 'USD', options?: NumberFormatOptions) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency, ...options }).format(
    Number(amount)
  )
}

const toDashLowerCase = (text: string) => {
  return text.replace(/\s+/gu, '-').toLowerCase()
}

const formatDate = (datetime: string) => {
  const date = new Date(datetime)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

const formatCrumbLabel = (pathname: string) => {
  const formatedPathname = pathname
    .replace('pages', ' ')
    .replace('collections', ' ')
    .replace('products', ' ')
  // eslint-disable-next-line require-unicode-regexp
  // .replace(/-/g, ' ')
  // .replace('/', ' ')
  // .replace('/', ' ')
  return formatedPathname.toUpperCase()
}

const base64Decode = (data: string) => atob(data)

const base64DecodeShopifyId = (data: string) => pipe(match(/\d+/u), head)(data) as string

export {
  formatMoney,
  toDashLowerCase,
  formatDate,
  base64DecodeShopifyId,
  base64Decode,
  formatCrumbLabel,
}
