import store from 'store'
import { AccessTokenType, Maybe } from '~/@types/models'

type AuthType = {
  saveUser: (data: AccessTokenType) => Promise<void>
  getUser: () => Maybe<AccessTokenType>
  logout: () => Promise<void>
}

const storeKey = 'user'

const useAuth = (): AuthType => {
  const getUser = () => {
    const user = store.get(storeKey)
    const expiresAt = new Date(user?.expiresAt).getTime()
    const now = new Date().getTime()

    if (expiresAt && now > expiresAt) {
      store.remove(storeKey)
      return null
    }
    return user
  }

  const saveUser = async (data: AccessTokenType) => {
    store.set(storeKey, data)
  }

  const removeUser = async () => {
    store.remove(storeKey)
  }

  const logout = async () => {
    await removeUser()
  }

  return {
    saveUser,
    logout,
    getUser,
  }
}

export default useAuth
