import React, { createContext, FC, useContext, useState } from 'react'

type OverlayContextType = {
  show: boolean
  toggleOverlay(value: boolean): void
}

const OverlayContext = createContext<OverlayContextType>({
  show: false,
  toggleOverlay: () => undefined,
})

const OverlayProvider: FC = ({ children }) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const state = {
    show: showOverlay,
    toggleOverlay: (value: boolean) => setShowOverlay(value),
  }
  return <OverlayContext.Provider value={state}>{children}</OverlayContext.Provider>
}

const useOverlayContext = () => {
  const context = useContext(OverlayContext)

  if (context === undefined) {
    throw new Error('useOverlayContext must be used within a OverlayProvider')
  }

  return context
}

export { OverlayProvider, useOverlayContext }
