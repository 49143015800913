import createClient from '../../../plugins/gatsby-source-bs-shopify/create-client-no-retry'
import { find, path, pipe } from 'ramda'
import {
  Maybe,
  CheckoutCreateInputType,
  CheckoutType,
  CheckoutLineItemUpdateInput,
  CheckoutLineItemInput,
  MailingAddressInput,
  CreditCardPaymentInputV2,
} from '~/@types/models'
import {
  addItemsMutation,
  applyDiscountMutation,
  checkoutCustomerAssociateMutation,
  checkoutEmailUpdateMutation,
  createCheckoutMutation,
  removeDiscountMutation,
  removeItemsMutation,
  replaceItemsMutation,
  shippingAddressUpdateMutation,
  tokenizedPaymentMutation,
  updateItemsMutation,
  updateShippingLineMutation,
  checkoutQuery,
  updateCheckoutMutation,
} from './mutations'
import { GraphQLClient } from 'graphql-request'

const store = process.env.GATSBY_SHOPIFY_SHOP_NAME
const accessToken = process.env.GATSBY_SHOPIFY_SF_ACCESS_TOKEN

export enum ShopifyErrorCodeType {
  UNIDENTIFIED_CUSTOMER = 'UNIDENTIFIED_CUSTOMER',
  EMAIL_TAKEN = 'TAKEN',
  INVALID = 'INVALID',
  INVALID_FOR_COUNTRY = 'INVALID_FOR_COUNTRY',
  INVALID_APPLY = 'UNABLE_TO_APPLY',
}

export type CheckoutProxyType = {
  createCheckout: (input: CheckoutCreateInputType) => Promise<CheckoutType>
  updateItems: (
    checkoutId: string,
    lineItems: CheckoutLineItemUpdateInput[]
  ) => Promise<CheckoutType>
  replaceItems: (checkoutId: string, lineItems: CheckoutLineItemInput[]) => Promise<CheckoutType>
  addItems: (checkoutId: string, lineItems: CheckoutLineItemInput[]) => Promise<CheckoutType>
  removeItems: (checkoutId: string, lineItemIds: string[]) => Promise<CheckoutType>
  applyDiscount: (checkoutId: string, discountCode: string) => Promise<CheckoutType>
  removeDiscount: (checkoutId: string) => Promise<CheckoutType>
  updateShippingAddress: (
    checkoutId: string,
    shippingAddress: MailingAddressInput
  ) => Promise<CheckoutType>
  updateCheckoutEmail: (checkoutId: string, email: string) => Promise<CheckoutType>
  updateShippingLine: (checkoutId: string, shippingRateHandle: string) => Promise<CheckoutType>
  associateCustomer: (checkoutId: string, customerAccessToken: string) => Promise<CheckoutType>
  completePayment: (checkoutId: string, payment: CreditCardPaymentInputV2) => Promise<CheckoutType>
  getCheckout: (checkoutId: string) => Promise<CheckoutType>
  updateCheckout: (checkoutId: string, attrs: any) => Promise<CheckoutType>
}

export const getErrorCode = (mutationName: string): Maybe<string> =>
  pipe(
    path([mutationName, 'userErrors']),
    find(path(['code'])),
    path<string>(['code'])
  )

const performRequest = async <T>(
  client: GraphQLClient,
  mutation: string,
  variables = {},
  responsePath: string[]
): Promise<T> => {
  let response = null

  try {
    response = await client.request(mutation, variables)
  } catch (e) {
    throw new Error(`Something went wrong. Try again later please`)
  }

  const mutationName = responsePath[0]
  const hasError = Boolean(path([mutationName, 'userErrors'], response))

  let errorCode = null

  if (hasError) {
    errorCode = getErrorCode(mutationName)(response)
  }

  if (errorCode === ShopifyErrorCodeType.INVALID_FOR_COUNTRY) {
    throw new Error(ShopifyErrorCodeType.INVALID_FOR_COUNTRY)
  }
  if (errorCode === ShopifyErrorCodeType.INVALID_APPLY) {
    throw new Error(ShopifyErrorCodeType.INVALID_APPLY)
  }

  // now we are sure that the T response is present
  return path<T>(responsePath, response) as T
}

const client = createClient(store, accessToken)

export const checkoutProxy = (): CheckoutProxyType => {
  const createCheckout = async (input: CheckoutCreateInputType) => {
    return await performRequest<CheckoutType>(client, createCheckoutMutation, { input }, [
      'checkoutCreate',
      'checkout',
    ])
  }

  const updateItems = async (checkoutId: string, lineItems: CheckoutLineItemUpdateInput[]) => {
    try {
      return await performRequest<CheckoutType>(
        client,
        updateItemsMutation,
        {
          checkoutId,
          lineItems,
        },
        ['checkoutLineItemsUpdate', 'checkout']
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const replaceItems = async (checkoutId: string, lineItems: CheckoutLineItemInput[]) => {
    return await performRequest<CheckoutType>(
      client,
      replaceItemsMutation,
      {
        checkoutId,
        lineItems,
      },
      ['checkoutLineItemsReplace', 'checkout']
    )
  }

  const updateCheckout = async (checkoutId: string, attrs: any) => {
    return await performRequest<CheckoutType>(
      client,
      updateCheckoutMutation,
      {
        checkoutId,
        input: {
          ...attrs,
        },
      },
      ['checkoutAttributesUpdateV2', 'checkout']
    )
  }

  const addItems = async (checkoutId: string, lineItems: CheckoutLineItemInput[]) => {
    return await performRequest<CheckoutType>(
      client,
      addItemsMutation,
      {
        checkoutId,
        lineItems,
      },
      ['checkoutLineItemsAdd', 'checkout']
    )
  }

  const removeItems = async (checkoutId: string, lineItemIds: string[]) => {
    return await performRequest<CheckoutType>(
      client,
      removeItemsMutation,
      {
        checkoutId,
        lineItemIds,
      },
      ['checkoutLineItemsRemove', 'checkout']
    )
  }

  const applyDiscount = async (checkoutId: string, discountCode: string) => {
    return await performRequest<CheckoutType>(
      client,
      applyDiscountMutation,
      {
        checkoutId,
        discountCode,
      },
      ['checkoutDiscountCodeApplyV2', 'checkout']
    )
  }

  const removeDiscount = async (checkoutId: string) => {
    return await performRequest<CheckoutType>(
      client,
      removeDiscountMutation,
      {
        checkoutId,
      },
      ['checkoutDiscountCodeRemove', 'checkout']
    )
  }

  const updateShippingAddress = async (
    checkoutId: string,
    shippingAddress: MailingAddressInput
  ) => {
    return await performRequest<CheckoutType>(
      client,
      shippingAddressUpdateMutation,
      {
        checkoutId,
        shippingAddress,
      },
      ['checkoutShippingAddressUpdateV2', 'checkout']
    )
  }

  const updateCheckoutEmail = async (checkoutId: string, email: string) => {
    return await performRequest<CheckoutType>(
      client,
      checkoutEmailUpdateMutation,
      {
        checkoutId,
        email,
      },
      ['checkoutEmailUpdateV2', 'checkout']
    )
  }

  const updateShippingLine = async (checkoutId: string, shippingRateHandle: string) => {
    return await performRequest<CheckoutType>(
      client,
      updateShippingLineMutation,
      {
        checkoutId,
        shippingRateHandle,
      },
      ['checkoutShippingLineUpdate', 'checkout']
    )
  }

  const associateCustomer = async (checkoutId: string, customerAccessToken: string) => {
    return await performRequest<CheckoutType>(
      client,
      checkoutCustomerAssociateMutation,
      {
        checkoutId,
        customerAccessToken,
      },
      ['checkoutCustomerAssociateV2', 'checkout']
    )
  }

  const completePayment = async (checkoutId: string, payment: CreditCardPaymentInputV2) => {
    return await performRequest<CheckoutType>(
      client,
      tokenizedPaymentMutation,
      {
        checkoutId,
        payment,
      },
      ['checkoutCompleteWithCreditCardV2', 'checkout']
    )
  }

  const getCheckout = async (checkoutId: string) => {
    return await performRequest<CheckoutType>(
      client,
      checkoutQuery,
      {
        checkoutId,
      },
      ['node']
    )
  }

  return {
    createCheckout,
    updateItems,
    replaceItems,
    removeItems,
    addItems,
    applyDiscount,
    removeDiscount,
    updateShippingAddress,
    updateCheckoutEmail,
    updateShippingLine,
    completePayment,
    getCheckout,
    associateCustomer,
    updateCheckout,
  }
}
