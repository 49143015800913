import React, { FC } from 'react'
// @ts-ignore
import sha1 from 'js-sha1'

export const getCookie = (key: string) => {
  if (typeof document === 'undefined') return ''
  const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')

  return keyValue ? keyValue[2] : ''
}

export const setCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}`
}

export const setImpactRadiusId = () => {
  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('irclickid')) {
    setCookie('IRCLICKID', String(urlParams.get('irclickid')))
  }
}

export const trackIdentifyUser = () => {
  // @ts-ignore
  if (window.ire) {
    let customerId = ``
    let customerEmail = ``

    try {
      const userData = localStorage.getItem('user')

      if (userData) {
        const user = JSON.parse(userData) as {
          accessToken: string
          email: string
          id: string
        }

        if (user.email) {
          const hash = sha1.create()
          hash.update(user.email)
          customerEmail = hash.hex()
        }

        if (user.id) {
          customerId = user.id
        }
      }

      // @ts-ignore
      window.ire('identify', { customerId, customerEmail })
    } catch (e) {
      console.error(e)
    }
  }
}

const ImpactRadius: FC = () => {
  return (
    <script
      id="impact-radius"
      dangerouslySetInnerHTML={{
        __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('//d.impactradius-event.com/A2242446-44a6-4f69-b80d-58b1ff4e6dae1.js','script','ire',document,window);`,
      }}
    />
  )
}

export default ImpactRadius
