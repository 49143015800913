import styled from 'styled-components'

export const DotsPulse = styled.div`
  position: relative;
  left: -9999px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  box-shadow: 9999px 0 0 -3px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
  }
  &:before {
    box-shadow: 9990px 0 0 -3px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  &:after {
    box-shadow: 10009px 0 0 -3px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9990px 0 0 -3px;
    }
    30% {
      box-shadow: 9990px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9990px 0 0 -3px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -3px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -3px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10009px 0 0 -3px;
    }
    30% {
      box-shadow: 10009px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10009px 0 0 -3px;
    }
  }
`

export const LoadingTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 18px;

  h2 {
    margin-bottom: 9px !important;
  }
`
