import { gql } from 'graphql-request'

export const customerQuery = gql`
  query GetCustomer($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      metafields(
        identifiers: [
          { namespace: "facts", key: "birth_date" }
          { namespace: "custom", key: "birth_date" }
          { namespace: "froonze_cp", key: "wishlist" }
        ]
      ) {
        key
        value
        namespace
      }
      id
      acceptsMarketing
      createdAt
      displayName
      email
      firstName
      lastName
      phone
      lastIncompleteCheckout {
        id
        createdAt
      }
      tags
      orders(first: 250, reverse: true) {
        edges {
          node {
            id
            fulfillmentStatus
            financialStatus
            processedAt
            orderNumber
            shippingAddress {
              id
              address1
              address2
              city
              company
              country
              countryCode
              firstName
              formatted
              formattedArea
              lastName
              latitude
              longitude
              name
              phone
              province
              provinceCode
              zip
            }
            totalPrice {
              currencyCode
              amount
            }
            subtotalPrice {
              currencyCode
              amount
            }
            currentSubtotalPrice {
              currencyCode
              amount
            }
            currentTotalPrice {
              currencyCode
              amount
            }
            currentTotalTax {
              currencyCode
              amount
            }
            totalShippingPrice {
              currencyCode
              amount
            }
            lineItems(first: 100) {
              edges {
                node {
                  currentQuantity
                  title
                  originalTotalPrice {
                    currencyCode
                    amount
                  }
                  quantity
                  variant {
                    id
                    image {
                      originalSrc: url
                      transformedSrc: url(transform: { maxWidth: 300, scale: 2 })
                    }
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
      defaultAddress {
        id
        address1
        address2
        city
        company
        country
        countryCode
        firstName
        formatted
        formattedArea
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
      }
      addresses(first: 10) {
        edges {
          node {
            id
            address1
            address2
            city
            company
            country
            countryCode
            firstName
            formatted
            formattedArea
            lastName
            latitude
            longitude
            name
            phone
            province
            provinceCode
            zip
          }
        }
      }
    }
  }
`

export const variantsQuery = gql`
  query GetVariants($variantIds: [ID!]!) {
    variants: nodes(ids: $variantIds) {
      ... on ProductVariant {
        id
        title
        image {
          url
          height
          width
        }
        product {
          handle
          id
        }
      }
    }
  }
`

export const wishlistQuery = gql`
  query GetVariants($variantIds: [ID!]!) {
    variants: nodes(ids: $variantIds) {
      ... on ProductVariant {
        id
        title
        image {
          url
          height
          width
        }
        product {
          handle
          id
        }
      }
    }
  }
`
