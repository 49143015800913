/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { FC, SVGProps } from 'react'

export enum Direction {
  'up' = 'up',
  'down' = 'down',
  'left' = 'left',
  'right' = 'right',
}

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: Direction
}

const rotate = (direction: Direction) => {
  switch (direction) {
    case 'down':
      return `rotate(90deg)`
    case 'left':
      return `rotate(180deg)`
    case 'up':
      return `rotate(-90deg)`
    case 'right':
    default:
      return 'none'
  }
}

export const Arrow: FC<Props> = ({
  color = 'currentColor',
  direction = Direction.right,
  ...rest
}) => {
  return (
    <Box
      as="svg"
      {...rest}
      // @ts-ignore
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      sx={{
        transform: rotate(direction),
      }}
    >
      <path
        d="M13 5.50355C13 5.28369 12.9107 5.07092 12.7528 4.91489L8.24089 0.255319C8.06234 0.0780142 7.87691 0 7.68463 0C7.24511 0 6.92921 0.319149 6.92921 0.751773C6.92921 0.978723 7.01849 1.17021 7.15584 1.31206L8.701 2.92908L10.6926 4.80851L9.09931 4.70922H0.776017C0.315901 4.70922 0 5.03546 0 5.50355C0 5.96454 0.315901 6.29078 0.776017 6.29078H9.09931L10.6926 6.19149L8.701 8.07092L7.15584 9.68794C7.01849 9.82979 6.92921 10.0213 6.92921 10.2482C6.92921 10.6809 7.24511 11 7.68463 11C7.87691 11 8.06234 10.922 8.22715 10.7589L12.7528 6.08511C12.9107 5.92908 13 5.71631 13 5.50355Z"
        fill={color}
      />
    </Box>
  )
}
