/* eslint-disable react/no-danger */
import React, { FC, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import urls from '~/common/urls'
import { CustomerType, Maybe } from '~/@types/models'

type QueryResult = {
  site: {
    siteMetadata: Pick<SiteMetaDataType, 'title' | 'siteUrl'>
  }
}

// const query = graphql`
//   query UserTrackingSnippet {
//     site {
//       siteMetadata {
//         title
//         siteUrl
//       }
//     }
//   }
// `
type Props = {
  customer: Maybe<CustomerType>
  tracking: boolean
  setTracking: any
}

const UserTrackingSnippet: FC<Props> = ({ customer, tracking, setTracking }) => {
  // const {
  //     site: {
  //     siteMetadata: { title, siteUrl },
  //     },
  // } = useStaticQuery<QueryResult>(query)
  const trackUser = (_learnq: any) => {
    const item = {
      $email: customer?.email,
      $first_name: customer?.firstName,
      $last_name: customer?.lastName,
    }
    _learnq.push(['identify', item])
  }
  // items mapped
  if (tracking && typeof _learnq !== 'undefined') {
    setTracking(false)
    trackUser(_learnq)
  }
  return <> </>
}

export default UserTrackingSnippet
