import React, { createContext, useContext, useState, useEffect } from 'react'
import { ImageType } from '~/@types/models'

export interface ISimpleProductType {
  title: string
  handle: string
  mainImage: ImageType
}

// Creating the context
const RecentlyViewedProductsContext = createContext({
  recentlyViewedProducts: [] as ISimpleProductType[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addProductToRecentlyViewed: (product: ISimpleProductType) => {},
})

const RecentlyViewedProductsProvider: React.FC = ({ children }) => {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<ISimpleProductType[]>([])

  useEffect(() => {
    const storedProducts = localStorage.getItem('recentlyViewedProducts')
    if (storedProducts) {
      setRecentlyViewedProducts(JSON.parse(storedProducts))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('recentlyViewedProducts', JSON.stringify(recentlyViewedProducts))
  }, [recentlyViewedProducts])

  const addProductToRecentlyViewed = (product: ISimpleProductType) => {
    setRecentlyViewedProducts(prevProducts => {
      const updatedProducts = [product, ...prevProducts.filter(p => p.handle !== product.handle)]
      return updatedProducts.slice(0, 12)
    })
  }

  return (
    <RecentlyViewedProductsContext.Provider
      value={{ recentlyViewedProducts, addProductToRecentlyViewed }}
    >
      {children}
    </RecentlyViewedProductsContext.Provider>
  )
}

export const useRecentlyViewedProducts = () => useContext(RecentlyViewedProductsContext)

export default RecentlyViewedProductsProvider
