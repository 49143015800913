import { gql } from 'graphql-request'

export const HOMEPAGE = gql`
  query Homepage($homeId: ID!) {
    data: metaobject(id: $homeId) {
      sections: fields {
        name: key
        content: reference {
          ... on Metaobject {
            type
            fields {
              name: key
              value
              reference {
                ... on Metaobject {
                  fields {
                    name: key
                    value
                    reference {
                      ... on Collection {
                        handle
                      }
                      ... on MediaImage {
                        alt
                        image {
                          originalSrc
                        }
                      }
                      ... on Metaobject {
                        fields {
                          name: key
                          value
                          reference {
                            ... on Collection {
                              handle
                            }
                            ... on MediaImage {
                              image {
                                originalSrc
                              }
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
