import styled from 'styled-components'
import { FONTS, H1, H2, H5, Paragraph } from '~/styled/components/typography'

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`

export const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 563px;
  width: 100%;
  background-color: #fff;
`

export const DialogTitle = styled(H5)`
  font-size: 20px;
  color: #808080;
  text-align: center;
  padding: 20px 20px 0;
  font-family: ${FONTS.Cormorant};
`

export const DialogClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #808080;
  font-size: 20px 20px 10px;
`

export const DialogTitleNumber = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${H1} {
    font-size: 64px;
    line-height: 0;
    font-family: ${FONTS.CormorantInfantLight};
  }

  ${H2} {
    font-size: 64px;
    text-align: center;
    font-weight: 400;
    font-family: ${FONTS.Cormorant};

    i {
      font-family: ${FONTS.Cormorant};
      font-weight: 400;
    }

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      font-size: 42px;
    }
  }
`

export const DialogContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #01182b;

  ${Paragraph} {
    font-size: 14px;
    margin: 0;
    font-family: ${FONTS.HelveticaNeue};
    strong {
      font-weight: 500;
    }
  }
`
