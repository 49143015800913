import createClient from '../../../plugins/gatsby-source-bs-shopify/create-client-no-retry'
import { find, path, pipe } from 'ramda'
import {
  Maybe,
  SignInUserInputType,
  AccessTokenType,
  CustomerType,
  SignUpUserInputType,
  CustomerAddressInputType,
  CustomerAddressType,
  CustomerInputType,
} from '~/@types/models'
import {
  signInMutation,
  signUpMutation,
  createAddressMutation,
  customerRecoverMutation,
  createWishlistMutation,
  deleteAddressMutation,
  updateCustomerAddressMutation,
  updateCustomerMutation,
  updateCustomerMutationAdmin,
  updateDefaultAddressMutation,
  updatePasswordMutation,
  updateWishlistMutation,
} from './mutations'
import { customerQuery } from './queries'
import { checkoutProxy, CheckoutProxyType } from './checkout'
import { GraphQLClient } from 'graphql-request'
// import { test } from '../../../plugins/gatsby-source-bs-shopify/gatsby-node'

// import createClient from '../../../plugins/gatsby-source-bs-shopify/create-client'
import createClientFroonze from '../../../plugins/gatsby-source-bs-shopify/create-client-froonze'

const store = process.env.GATSBY_SHOPIFY_SHOP_NAME
const accessToken = process.env.GATSBY_SHOPIFY_SF_ACCESS_TOKEN
const adminAccessToken = process.env.SHOPIFY_ADMIN_ACCESS_TOKEN
const froonzeShopToken = '7b316c0f32aab12241da04186ef65efcc7f3bd44c5f85ea41b0955bead454d1c'
const froonzeToken = '68cf95921c545c5113e5be60565cd1a1c8b5c4da8bc1625363789ec63170e91b'
const adminApiKey = process.env.SHOPIFY_ADMIN_API_KEY

// const Shopify = require('shopify-api-node')
// const algo = test();
// console.log(algo);

enum ShopifyErrorCodeType {
  UNIDENTIFIED_CUSTOMER = 'UNIDENTIFIED_CUSTOMER',
  CUSTOMER_DISABLED = 'CUSTOMER_DISABLED',
  EMAIL_TAKEN = 'TAKEN',
  INVALID = 'INVALID',
  PHONE_TAKEN = 'phone',
}

type ClientProxyType = {
  signIn: (input: SignInUserInputType) => Promise<AccessTokenType>
  signUp: (input: SignUpUserInputType) => Promise<CustomerType>
  fetchCustomer: (accessToken: string) => Promise<CustomerType>
  createAddress: (
    authToken: string,
    address: CustomerAddressInputType
  ) => Promise<CustomerAddressType>
  updateDefaultAddress: (authToken: string, addressId: string) => Promise<CustomerAddressType>
  updatePassword: (password: string, authToken: string) => Promise<void>
  recoverPassword: (authToken: string, email: string) => Promise<void>
  deleteAddress: (authToken: string, id: string) => Promise<void>
  updateCustomer: (authToken: string, customer: CustomerInputType) => Promise<CustomerInputType>
  updateAddress: (
    authToken: string,
    addressId: string,
    address: CustomerInputType
  ) => Promise<CustomerAddressType>
  checkout: () => CheckoutProxyType
  getClient: () => GraphQLClient
  createWishlist: (accessToken: string, projectName: string) => Promise<void>
  addToWishlist: (
    accessToken: string,
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => Promise<void>
  removeFromWishlist: (
    accessToken: string,
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => Promise<void>
}

const getErrorCode = (mutationName: string): Maybe<string> =>
  pipe(
    path([mutationName, 'customerUserErrors']),
    find(path(['code'])),
    path<string>(['code'])
  )

const getErrorField = (mutationName: string): Maybe<string> =>
  pipe(
    path([mutationName, 'customerUserErrors']),
    find(path(['field'])),
    path<string[]>(['field'])
  )

const performRequest = async <T>(
  client: GraphQLClient,
  mutation: string,
  variables = {},
  responsePath: string[]
): Promise<T> => {
  let response = null
  // console.log(client);
  try {
    response = await client.request(mutation, variables)
    if (!response) {
      throw new Error('Something went wrong. Try again later please')
    }
  } catch (e) {
    throw new Error(`Something went wrong. Try again later please`)
  }

  const mutationName = responsePath[0]
  const hasError = Boolean(path([mutationName, 'customerUserErrors'], response)) || false

  let errorCode = null
  let fieldError = null

  if (hasError) {
    errorCode = getErrorCode(mutationName)(response)
    fieldError = getErrorField(mutationName)(response)
  }

  if (errorCode === ShopifyErrorCodeType.UNIDENTIFIED_CUSTOMER) {
    throw new Error('Email or password is not valid')
  }

  if (errorCode === ShopifyErrorCodeType.EMAIL_TAKEN) {
    if (fieldError.includes(ShopifyErrorCodeType.PHONE_TAKEN)) {
      throw new Error(`This phone number is already associated with an account.`)
    }
    throw new Error(
      `This email address is already associated with an account.
      If this account is yours, you can reset your password.`
    )
  }
  if (errorCode === ShopifyErrorCodeType.CUSTOMER_DISABLED) {
    const message = response[mutationName].customerUserErrors[0].message
    throw new Error(message)
  }

  if (errorCode === ShopifyErrorCodeType.INVALID) {
    throw new Error('Invalid values')
  }

  if (errorCode) {
    const message =
      response[mutationName].customerUserErrors[0].message ||
      'Something went wrong. Try again later please'
    throw new Error(message)
  }

  // now we are sure that the T response is present
  return path<T>(responsePath, response) as T
}

const client = createClient(store, accessToken) as GraphQLClient

export const clientProxyFactory = (): ClientProxyType => {
  const signIn = async (input: SignInUserInputType) => {
    return await performRequest<AccessTokenType>(client, signInMutation, { input }, [
      'customerAccessTokenCreate',
      'customerAccessToken',
    ])
  }

  const signUp = async (input: SignUpUserInputType) => {
    return await performRequest<CustomerType>(client, signUpMutation, { input }, [
      'customerCreate',
      'customer',
    ])
  }

  const fetchCustomer = async (authToken: string) => {
    return await performRequest<CustomerType>(client, customerQuery, { accessToken: authToken }, [
      'customer',
    ])
  }

  const createAddress = async (authToken: string, address: CustomerAddressInputType) => {
    return await performRequest<CustomerAddressType>(
      client,
      createAddressMutation,
      { address, customerAccessToken: authToken },
      ['customerAddressCreate', 'customerAddress']
    )
  }

  const createWishlist = async (authToken: string, projectName: string) => {
    return await performRequest<void>(
      froonzeClient,
      createWishlistMutation,
      {
        input: {
          externalId: '5504760610880',
          froonzeToken: '68cf95921c545c5113e5be60565cd1a1c8b5c4da8bc1625363789ec63170e91b',
          list: projectName,
        },
      },
      ['createWishlistList']
    )
  }

  const addToWishlist = async (
    authToken: string,
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => {
    return await performRequest<void>(
      froonzeClient,
      updateWishlistMutation,
      {
        input: {
          handle: productHandle,
          action: 'add',
          customerExternalId: customerId,
          froonzeToken: '68cf95921c545c5113e5be60565cd1a1c8b5c4da8bc1625363789ec63170e91b',
          productExternalId: productId,
          variantExternalId: variantId,
          list: projectName,
          guestId: null,
        },
      },
      ['updateWishlist']
    )
  }

  const removeFromWishlist = async (
    authToken: string,
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => {
    return await performRequest<void>(
      froonzeClient,
      updateWishlistMutation,
      {
        input: {
          handle: productHandle,
          action: 'remove',
          customerExternalId: customerId,
          froonzeToken: '68cf95921c545c5113e5be60565cd1a1c8b5c4da8bc1625363789ec63170e91b',
          productExternalId: productId,
          variantExternalId: variantId,
          list: projectName,
          guestId: null,
        },
      },
      ['updateWishlist']
    )
  }

  const updateDefaultAddress = async (authToken: string, id: string) => {
    return await performRequest<CustomerAddressType>(
      client,
      updateDefaultAddressMutation,
      { addressId: id, customerAccessToken: authToken },
      ['customerAddressCreate', 'customerAddress']
    )
  }

  const updatePassword = async (authToken: string, password: string) => {
    return await performRequest<void>(
      client,
      updatePasswordMutation,
      { customer: { password }, customerAccessToken: authToken },
      ['customerUpdate', 'customer']
    )
  }

  const updateCustomer = async (authToken: string, customer: CustomerInputType) => {
    return await performRequest<CustomerInputType>(
      client,
      updateCustomerMutation,
      { customer, customerAccessToken: authToken },
      ['customerUpdate']
    )
  }

  const updateCustomerBirthdate = async () => {
    return await performRequest<CustomerInputType>(
      clientAdmin,
      updateCustomerMutationAdmin,
      {
        input: {
          id: 'gid://shopify/Customer/5504760610880',
          firstName: 'Francisco',
          lastName: 'Gonzale',
        },
      },
      ['customerUpdate']
    )
  }

  const recoverPassword = async (authToken: string, email: string) => {
    return await performRequest<void>(client, customerRecoverMutation, { email }, [
      'customerRecover',
    ])
  }

  const deleteAddress = async (authToken: string, id: string) => {
    return await performRequest<void>(
      client,
      deleteAddressMutation,
      { id, customerAccessToken: authToken },
      ['customerAddressDelete', 'deletedCustomerAddressId']
    )
  }

  const updateAddress = async (
    authToken: string,
    id: string,
    address: CustomerAddressInputType
  ) => {
    return await performRequest<CustomerAddressType>(
      client,
      updateCustomerAddressMutation,
      { address, id, customerAccessToken: authToken },
      ['customerAddressUpdate', 'customerAddress']
    )
  }

  return {
    signIn,
    signUp,
    fetchCustomer,
    createAddress,
    updateDefaultAddress,
    updatePassword,
    recoverPassword,
    deleteAddress,
    updateCustomer,
    updateCustomerBirthdate,
    updateAddress,
    checkout: checkoutProxy,
    getClient: () => client,
    createWishlist,
    addToWishlist,
    removeFromWishlist,
  }
}
