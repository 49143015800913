import { H2 } from '~/styled/components/typography'
import React from 'react'
import { DotsPulse, LoadingTextWrapper } from './styled'

export const LoadingText = () => {
  return (
    <LoadingTextWrapper>
      <H2
        style={{
          fontSize: '1.5rem',
          fontFamily: 'Cormorant Garamond',
          color: '#0d0d0d',
          fontWeight: 400,
          marginBottom: '9px !important',
        }}
      >
        Loading
      </H2>
      <DotsPulse />
    </LoadingTextWrapper>
  )
}
