import styled, { css } from 'styled-components'

export const FONTS = {
  Cormorant: "'Cormorant Garamond', 'Times New Roman', serif",
  CormorantInfantLight: "'Cormorant Infant Light', serif",
  CormorantUnicaseLight: "'Cormorant Garamond Light', serif",
  Inter: "'Inter', sans-serif",
  HelveticaNeue: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  Helvetica:
    "Helvetica, Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
}

export const BaseTitle = css`
  font-family: ${FONTS.Cormorant};
  line-height: 1.125;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-bottom: 1em;

  a {
    color: inherit;
  }

  .small {
    font-size: 13px;
    font-weight: 400;
    margin-left: 10px;
    color: #808080;
    text-decoration: none;
    border-bottom: 1px solid #595858;
  }
`

export const H1 = styled.h1`
  ${BaseTitle}
  font-size: 40px;
  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    font-size: 28px;
  }
`

export const H2 = styled.h2`
  ${BaseTitle}
  font-size: 32px;
  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    font-size: 22px;
  }
`

export const H3 = styled.h3`
  ${BaseTitle}
  font-size: 28px;
  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    font-size: 20px;
  }
`

export const H4 = styled.h4`
  ${BaseTitle}
  font-size: 23px;
  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    font-size: 18px;
  }
`

export const H5 = styled.h5`
  ${BaseTitle}
  font-size: 18px;
  font-family: ${FONTS.Helvetica};
  ${({ theme }) => '@media' + theme.mediaQueries.mobile} {
    font-size: 14px;
  }
`

export const H6 = styled.h6`
  ${BaseTitle}
  font-size: 16px;
  font-family: ${FONTS.Helvetica};
  ${({ theme }) => '@media' + theme.mediaQueries.mobile} {
    font-size: 14px;
  }
`

export const Paragraph = styled.p`
  font-family: ${FONTS.Helvetica};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-bottom: 1.5em;
  ${({ theme }) => '@media' + theme.mediaQueries.mobile} {
    font-size: 14px;
  }
`

export const Text = styled.div`
  font-family: ${FONTS.Helvetica};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-bottom: 1.5em;
  ${({ theme }) => '@media' + theme.mediaQueries.mobile} {
    font-size: 14px;
  }
`

export const SmallText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  letter-spacing: 0.5px;
  text-decoration: none;
  ${({ theme }) => '@media' + theme.mediaQueries.mobile} {
    font-size: 11px;
  }
`
