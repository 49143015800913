import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useAuth from '~/hooks/use-auth'
import {
  AccessTokenType,
  CustomerAddressInputType,
  CustomerInputType,
  CustomerType,
  TypeOfCustomerType,
  Maybe,
  SignInUserInputType,
} from '~/@types/models'
import useShopifyClient from '~/hooks/use-shopify-client'
import { pipe, prop } from 'ramda'
import { getArray } from '~/utils/common'
import { env } from '~/common/env'
import { base64DecodeShopifyId } from '~/utils/format'
import UserTrackingSnippet from '~/components/klaviyo/logged-user-snippet'
import { CustomerTitleSegment } from './type'

import algoliasearch from 'algoliasearch/lite'

type CustomerContextType = {
  isLoggedIn: boolean
  auth: Maybe<AccessTokenType>
  customer: Maybe<CustomerType>
  getAuth: () => Maybe<AccessTokenType>
  logout: (callback: Function) => Promise<void>
  signIn: (values: SignInUserInputType, siteUrl: string) => Promise<void>
  updateCustomer: (values: CustomerInputType) => Promise<void>
  updateCustomerBirthdate: () => Promise<void>
  fetchCustomer: () => Promise<void>
  validatePassword: (values: SignInUserInputType, siteUrl: string) => Promise<void>
  addAddress: (values: CustomerAddressInputType) => Promise<void>
  updateDefaultAddress: (id: string) => Promise<void>
  deleteAddress: (id: string) => Promise<void>
  updateAddress: (id: string, values: CustomerAddressInputType) => Promise<void>
  resetPassword: (email: string) => Promise<void>
  createWishlist: (projectName: string) => Promise<void>
  addProductToWishlist: (
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => Promise<void>
  removeProductFromWishlist: (
    projectName: string,
    productHandle: string,
    productId: string,
    variantId: string,
    customerId: string
  ) => Promise<void>
  customerType: TypeOfCustomerType
  setIsLoggedIn: (value: boolean) => void
  isTrade: boolean
  isVip: boolean
}

const CustomerContext = createContext<CustomerContextType>({
  getAuth: () => null,
  isLoggedIn: false,
  auth: null,
  customer: null,
  logout: async () => undefined,
  signIn: async (values, siteUrl) => undefined,
  updateCustomer: async values => undefined,
  updateCustomerBirthdate: async () => undefined,
  fetchCustomer: async () => undefined,
  createWishlist: async projectName => undefined,
  addProductToWishlist: async (projectName, productHandle, productId, variantId, customerId) =>
    undefined,
  removeProductFromWishlist: async (projectName, productHandle, productId, variantId, customerId) =>
    undefined,
  validatePassword: async (values, siteUrl) => undefined,
  addAddress: async values => undefined,
  updateDefaultAddress: async id => undefined,
  deleteAddress: async id => undefined,
  updateAddress: async (id, values) => undefined,
  customerType: {
    trade: false,
    vip: false,
  },
  setIsLoggedIn: () => undefined,
})

const CustomerProvider: FC = ({ children }) => {
  const { saveUser, logout: authLogout, getUser } = useAuth()
  const { client } = useShopifyClient()
  const [customer, setCustomer] = useState<CustomerType | null>(null)
  const [tracking, setTracking] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const getCustomerTags = pipe(prop('tags'), getArray, data => data.length > 0)

  const algolia = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID as string,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
  )

  algolia.initIndex('products')

  const getCustomerTag = (tag: string, caseSensitive = false) => {
    if (getCustomerTags(customer)) {
      const finalTagString = caseSensitive ? tag : String(tag).toLowerCase()
      return customer.tags.indexOf(finalTagString) >= 0
    }
    return false
  }

  const customerType = () => {
    return {
      trade: getCustomerTag('trade') || getCustomerTag('Trade', true),
      vip: getCustomerTag('vip'),
    }
  }

  const { isTrade, isVip } = useMemo(() => {
    if (customer) {
      if (typeof window !== 'undefined') {
        const idPopUp = localStorage.getItem('idPopUp') || 'Unidentified'

        if (customerType().trade) {
          if (idPopUp === CustomerTitleSegment.RETAIL) {
            localStorage.setItem('idPopUp', CustomerTitleSegment.COMMERCIAL)
          }
        }

        const customerToTag = customer?.email?.includes('@bensoleimani.com')
          ? 'Bensoleimani'
          : customerType().trade
          ? 'Trade'
          : 'Regular'

        const customerId = base64DecodeShopifyId(customer.id)

        window.hj('identify', customerId, {
          email: customer?.email,
          customer_type: customerToTag,
          idPopUp,
        })

        localStorage.setItem('customer_type', customerToTag)
        localStorage.setItem('email', customer?.email)
      }
      return {
        isTrade: customerType().trade,
        isVip: customerType().vip,
      }
    }

    if (typeof window !== 'undefined') {
      window.hj('identify', null, {
        email: '',
        customer_type: 'Unidentified',
      })
      localStorage.setItem('customer_type', 'Unidentified')
      localStorage.setItem('email', '')
    }
    return {
      isTrade: false,
      isVip: false,
    }
  }, [customer])

  const [auth, setAuth] = useState<Maybe<AccessTokenType>>(() => {
    return getUser()
  })

  useEffect(() => {
    if (auth && auth.accessToken && isLoggedIn === false) {
      setIsLoggedIn(true)
    } else if (!auth && isLoggedIn === true) {
      setIsLoggedIn(false)
    }
  }, [auth])

  const logout = useCallback(
    async (callback: Function) => {
      setCustomer(null)
      setAuth(null)
      await authLogout()
      callback()
    },
    [authLogout]
  )

  const fetchCustomer = useCallback(async () => {
    const customer = await client.fetchCustomer(auth?.accessToken)
    setCustomer(customer)
    setTracking(true)

    if (!customer) {
      setIsLoggedIn(false)
      authLogout()
    }
  }, [auth])

  const resetPassword = useCallback(
    async email => {
      await client.recoverPassword(auth?.accessToken, email)
    },
    [auth]
  )

  const saveAuth = useCallback(
    (auth: AccessTokenType) => {
      setAuth(auth)
      saveUser(auth)
    },
    [saveUser]
  )

  const signIn = useCallback(
    async (values: { email: string; password: string }, siteUrl: string) => {
      try {
        const response = await client.signIn({
          email: values.email,
          password: values.password,
        })

        const customer = await client.fetchCustomer(response?.accessToken)
        if (customer) {
          setTracking(true)
        }
        await saveAuth({
          ...response,
          // @ts-ignore
          email: customer.email,
          id: base64DecodeShopifyId(customer.id),
        })

        const multipass = env.shopifyMultipassSecret

        if (response.accessToken && String(multipass) && siteUrl) {
          const Multipassify = require('multipassify')
          const multipassify = new Multipassify(String(multipass))
          const customerData = {
            email: values.email,
            return_to: `${siteUrl}`,
          }
          const token = multipassify.encode(customerData)
          const url = multipassify.generateUrl(customerData, `${env.shopifyShopName}.myshopify.com`)
        }
      } catch (error) {
        console.error(error)
        throw new Error('Invalid email or password')
      }
    },
    [saveAuth]
  )

  const validatePassword = useCallback(
    async (values: { email: string; password: string }, siteUrl: string) => {
      const response = await client.signIn({
        email: values.email,
        password: values.password,
      })
      await fetchCustomer()
    },
    [auth]
  )

  const createWishlist = useCallback(
    async (projectName: string) => {
      const customerWishlist = await client.createWishlist(auth?.accessToken, projectName)
    },
    [auth]
  )

  const addProductToWishlist = useCallback(
    async (
      projectName: string,
      productHandle: string,
      productId: string,
      variantId: string,
      customerId: string
    ) => {
      await client.addToWishlist(
        auth?.accessToken,
        projectName,
        productHandle,
        productId,
        variantId,
        customerId
      )
      await fetchCustomer()
    },
    [auth]
  )

  const removeProductFromWishlist = useCallback(
    async (
      projectName: string,
      productHandle: string,
      productId: string,
      variantId: string,
      customerId: string
    ) => {
      await client.removeFromWishlist(
        auth?.accessToken,
        projectName,
        productHandle,
        productId,
        variantId,
        customerId
      )
      await fetchCustomer()
    },
    [auth]
  )

  const updateCustomerBirthdate = useCallback(
    async (values: SignInUserInputType) => {
      let accessToken = auth?.accessToken
      const response = await client.updateCustomerBirthdate()

      const newAuth = prop('customerAccessToken', response)

      if (newAuth) {
        saveAuth(newAuth)
        accessToken = newAuth.accessToken
      }

      const customer = await client.fetchCustomer(accessToken)
      setCustomer(customer)
      setTracking(true)
    },
    [auth]
  )

  const updateCustomer = useCallback(
    async (values: SignInUserInputType) => {
      let accessToken = auth?.accessToken
      const response = await client.updateCustomer(accessToken, values)

      const newAuth = prop('customerAccessToken', response)

      if (newAuth) {
        saveAuth(newAuth)
        accessToken = newAuth.accessToken
      }

      const customer = await client.fetchCustomer(accessToken)
      setCustomer(customer)
      setTracking(true)
    },
    [auth]
  )

  const addAddress = useCallback(
    async (values: CustomerAddressInputType) => {
      await client.createAddress(auth?.accessToken, values)
      await fetchCustomer()
    },
    [auth]
  )

  const updateDefaultAddress = useCallback(
    async (id: string) => {
      await client.updateDefaultAddress(auth?.accessToken, id)
      await fetchCustomer()
    },
    [auth]
  )

  const deleteAddress = useCallback(
    async (id: string) => {
      await client.deleteAddress(auth?.accessToken, id)
      await fetchCustomer()
    },
    [auth]
  )

  const updateAddress = useCallback(
    async (id: string, values: CustomerAddressInputType) => {
      await client.updateAddress(auth?.accessToken, id, values)
      // console.log(id, "Id de del cliente", values)
      await fetchCustomer()
    },
    [auth]
  )

  return (
    <CustomerContext.Provider
      value={{
        auth,
        isLoggedIn,
        customer,
        customerType: customerType(),
        logout,
        saveUser,
        signIn,
        updateCustomer,
        updateCustomerBirthdate,
        createWishlist,
        addProductToWishlist,
        removeProductFromWishlist,
        fetchCustomer,
        validatePassword,
        addAddress,
        updateDefaultAddress,
        deleteAddress,
        updateAddress,
        resetPassword,
        setIsLoggedIn,
        isTrade,
        isVip,
      }}
    >
      {tracking && (
        <UserTrackingSnippet customer={customer} tracking={tracking} setTracking={setTracking} />
      )}
      {children}
    </CustomerContext.Provider>
  )
}

const useCustomerContext = () => {
  const context = useContext(CustomerContext)

  if (context === undefined) {
    throw new Error('useCustomerContext must be used within a CustomerProvider')
  }

  return context
}

export { CustomerProvider, useCustomerContext }

export default CustomerProvider
