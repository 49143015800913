import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  color?: string
}

export const Close: FC<Props> = ({ color = '#666666', ...rest }) => {
  return (
    <svg {...rest} width="10" height="10" viewBox="0 0 10 10">
      <path
        d="M0.16318 9.04209C-0.0512289 9.25654 -0.0575351 9.62235 0.16318 9.83679C0.383896 10.0512 0.749653 10.0512 0.964062 9.83679L5 5.80022L9.03594 9.83679C9.25035 10.0512 9.62241 10.0575 9.83682 9.83679C10.0512 9.61604 10.0512 9.25654 9.83682 9.04209L5.80088 4.99921L9.83682 0.962637C10.0512 0.748194 10.0575 0.382379 9.83682 0.167936C9.61611 -0.052814 9.25035 -0.052814 9.03594 0.167936L5 4.20451L0.964062 0.167936C0.749653 -0.052814 0.37759 -0.0591212 0.16318 0.167936C-0.0512289 0.388686 -0.0512289 0.748194 0.16318 0.962637L4.19912 4.99921L0.16318 9.04209Z"
        fill={color}
      />
    </svg>
  )
}
