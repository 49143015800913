export const MOCK: ApiDiscountTrigger = {
  validationType: 'both', // quantity | amount
  quantityItems: 3,
  quantityAmount: 2500,
  expires: 15, // minutes
  discountType: 'percentage', // percentage | fixed
  discountValue: 10,
  message: '10% off',
}

interface ApiDiscountTrigger {
  validationType: 'quantity' | 'amount' | 'both'
  quantityItems: number
  quantityAmount: number
  discountType: 'percentage' | 'fixed'
  discountValue: number
  message: string
  expires: number
}
