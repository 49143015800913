import { gql } from 'graphql-request'

export const BREADCRUMB = gql`
  query Breadcrumb {
    breadcrumb: metaobject(id: "gid://shopify/Metaobject/7952531520") {
      fields {
        references(first: 10) {
          nodes {
            ... on Metaobject {
              type: handle
              fields {
                value
                type
                references(first: 10) {
                  nodes {
                    ... on Collection {
                      handle
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
