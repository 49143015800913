import { clientProxyFactory } from '~/utils/shopify-client'

const useShopifyClient = () => {
  const clientProxy = clientProxyFactory()

  return {
    client: clientProxy,
  }
}

export default useShopifyClient
