import { gql } from 'graphql-request'

export const signInMutation = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const signUpMutation = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const createAddressMutation = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const deleteAddressMutation = gql`
  mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        code
        field
        message
      }
      deletedCustomerAddressId
    }
  }
`

export const updateDefaultAddressMutation = gql`
  mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
      customer {
        defaultAddress {
          id
        }
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const updatePasswordMutation = gql`
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const customerRecoverMutation = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const createWishlistMutation = gql`
  mutation createWishlistList($input: CreateWishlistListInput!) {
    createWishlistList(input: $input) {
      error
      list
    }
  }
`

export const updateWishlistMutation = gql`
  mutation updateWishlist($input: UpdateWishlistInput!) {
    updateWishlist(input: $input) {
      error
      variantExternalIds
      inWishlist
      productId
      productTitle
      productPrice
      guestId
      list
    }
  }
`

// export const updateCustomerMutationAdmin = gql`
// mutation updateCustomerMetafields($input: CustomerInput!) {
//   customerUpdate(customer: $input) {
//     customer {
//       id
//       metafields(first: 3) {
//         edges {
//           node {
//             id
//             namespace
//             key
//             value
//           }
//         }
//       }
//     }
//     userErrors {
//       message
//       field
//     }
//   }
// }
// `

export const updateCustomerMutation = gql`
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const updateCustomerMutationAdmin = gql`
  mutation customerUpdate($input: CustomerInput!) {
    customerUpdate(input: $input) {
      userErrors {
        field
        message
      }
      customer {
        id
        firstName
        lastName
      }
    }
  }
`

export const updateCustomerAddressMutation = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const checkoutNode = `
id
email
webUrl
createdAt
completedAt
orderStatusUrl
order {
  fulfillmentStatus
  financialStatus
  orderNumber
}
shippingLine {
  title
  handle
  price {
    amount
    currencyCode
  }
}
discountApplications(first: 100) {
 edges {
  node {
    __typename
    ... on DiscountCodeApplication {
      code
    }
    ... on ScriptDiscountApplication {
      title
    }
    ... on AutomaticDiscountApplication {
      title
    }
    allocationMethod
    targetSelection
    targetType
    value {
      __typename
      ... on MoneyV2 {
        amount
        currencyCode
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
  }
 }
}
shippingAddress {
  address1,
  address2,
  city,
  company,
  country,
  firstName,
  lastName,
  phone,
  province,
  zip
}
subtotalPrice {
  amount
  currencyCode
}
paymentDue {
  amount
  currencyCode
}
totalPrice {
  amount
  currencyCode
}
lineItemsSubtotalPrice {
  amount
  currencyCode
}
customAttributes {
  key
  value
}
lineItems(first: 100) {
  edges {
    node {
      id
      title
      quantity
      shopifyId: id
      customAttributes {
        key
        value
      }
      unitPrice {
        amount
        currencyCode
      }
      discountAllocations {
        allocatedAmount {
          amount
        }
        discountApplication {
          ... on ScriptDiscountApplication {
            title
          }
          ... on AutomaticDiscountApplication {
            title
          }
          allocationMethod
          value {
            __typename
            ... on MoneyV2 {
              amount
              currencyCode
            }
            ... on PricingPercentageValue {
              percentage
            }
          }
        }
      }
        
      variant {
        id
        title
        quantityAvailable
        selectedOptions {
          name
          value
        }
        currentlyNotInStock
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        image{
          originalSrc: url
          transformedSrc: url(transform: {maxWidth:300, scale: 2})
        }
        product {
          handle
          tags
          productType
           metafields(identifiers: [
            { namespace: "custom_fields", key: "product_swatches" }
            { namespace: "custom", key: "rug_design" }
            { namespace: "custom_fields", key: "pile_height_filter" }
            { namespace: "custom_fields", key: "size_filter" }
            { namespace: "custom_fields", key: "color_filter" }
            { namespace: "custom_fields", key: "fiber_filter" }
            { namespace: "custom_fields", key: "shape_filter" }
            { namespace: "custom_fields", key: "pairs_well_with" }
            { namespace: "custom_fields", key: "product_specs_fabric_content" }
            { namespace: "custom_fields", key: "product_specs_pile_height" }
            { namespace: "custom_fields", key: "product_specs_sizes" }
            { namespace: "custom_fields", key: "product_specs_coo" }
            { namespace: "custom_fields", key: "product_specs_goodweave" }
            { namespace: "custom_fields", key: "color_group_products" }
            { namespace: "custom_fields", key: "product_video" }
            { namespace: "custom_fields", key: "product_video_caption" }
            { namespace: "custom_fields", key: "dimensions" }
            { namespace: "custom_fields", key: "template_key" }
            { namespace: "custom_fields", key: "you_might_like" }
            { namespace: "custom_fields", key: "frequently_purchased_with" }
            { namespace: "custom_fields", key: "disable_order_swatch" }
            { namespace: "custom_fields", key: "product_preorder_message" }
            { namespace: "custom_fields", key: "installation_guide_link" }
            { namespace: "custom_fields", key: "artwork_image" }
            { namespace: "custom_fields", key: "zoom_on_off" }
            { namespace: "custom_fields", key: "color" }
            { namespace: "custom", key: "rug_colors" }
            { namespace: "custom", key: "rug_fiber" }
            { namespace: "custom", key: "rug_style" }
            { namespace: "custom", key: "rug_size" }
            { namespace: "custom", key: "rug_pile_height" }
            { namespace: "custom", key: "shape" }
            { namespace: "custom", key: "variant_selection" }
            { namespace: "custom_fields", key: "color_group_products" }
          ]) {
            key
            value
          }
        }
        metafields(identifiers: [
            { namespace: "custom_fields", key: "product_swatches" }
            { namespace: "custom", key: "rug_design" }
            { namespace: "custom_fields", key: "pile_height_filter" }
            { namespace: "custom_fields", key: "size_filter" }
            { namespace: "custom_fields", key: "color_filter" }
            { namespace: "custom_fields", key: "fiber_filter" }
            { namespace: "custom_fields", key: "shape_filter" }
            { namespace: "custom_fields", key: "pairs_well_with" }
            { namespace: "custom_fields", key: "product_specs_fabric_content" }
            { namespace: "custom_fields", key: "product_specs_pile_height" }
            { namespace: "custom_fields", key: "product_specs_sizes" }
            { namespace: "custom_fields", key: "product_specs_coo" }
            { namespace: "custom_fields", key: "product_specs_goodweave" }
            { namespace: "custom_fields", key: "color_group_products" }
            { namespace: "custom_fields", key: "product_video" }
            { namespace: "custom_fields", key: "product_video_caption" }
            { namespace: "custom_fields", key: "dimensions" }
            { namespace: "custom_fields", key: "template_key" }
            { namespace: "custom_fields", key: "you_might_like" }
            { namespace: "custom_fields", key: "frequently_purchased_with" }
            { namespace: "custom_fields", key: "disable_order_swatch" }
            { namespace: "custom_fields", key: "product_preorder_message" }
            { namespace: "custom_fields", key: "installation_guide_link" }
            { namespace: "custom_fields", key: "artwork_image" }
            { namespace: "custom_fields", key: "zoom_on_off" }
            { namespace: "custom_fields", key: "color" }
            { namespace: "custom", key: "rug_colors" }
            { namespace: "custom", key: "rug_fiber" }
            { namespace: "custom", key: "rug_style" }
            { namespace: "custom", key: "rug_size" }
            { namespace: "custom", key: "rug_pile_height" }
            { namespace: "custom", key: "shape" }
            { namespace: "custom", key: "variant_selection" }
            { namespace: "custom_fields", key: "color_group_products" }
        ]) {
          key
          value
        }
      }
    }
  }
}
`

export const updateItemsMutation = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
  checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout { ${checkoutNode} }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
`

export const checkoutQuery = gql`
  query getCheckout($checkoutId: ID!) {
    node(
        id: $checkoutId
    ) {
        id
        ... on Checkout {
          ${checkoutNode}
        }
      }
  }
  `

export const replaceItemsMutation = gql`
  mutation checkoutLineItemsReplace($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ${checkoutNode}
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export const updateCheckoutMutation = gql`
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const addItemsMutation = gql`
  mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const removeItemsMutation = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const applyDiscountMutation = gql`
  mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const removeDiscountMutation = gql`
  mutation checkoutDiscountCodeApplyV2($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const shippingAddressUpdateMutation = gql`
  mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
    checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const checkoutEmailUpdateMutation = gql`
  mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const updateShippingLineMutation = gql`
  mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
    checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const checkoutCustomerAssociateMutation = gql`
  mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`

export const tokenizedPaymentMutation = gql`
  mutation checkoutCompleteWithCreditCardV2($checkoutId: ID!, $payment: CreditCardPaymentInputV2!) {
    checkoutCompleteWithCreditCardV2(checkoutId: $checkoutId, payment: $payment) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
      payment {
        id
      }
    }
  }
`

export const createCheckoutMutation = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ${checkoutNode}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
