/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { FC, SVGProps } from 'react'

enum Direction {
  'up' = 'up',
  'down' = 'down',
  'left' = 'left',
  'right' = 'right',
}

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: Direction
  customHeight?: string
  height?: string
}

const rotate = (direction: Direction) => {
  switch (direction) {
    case 'down':
      return `rotate(90deg)`
    case 'left':
      return `rotate(180deg)`
    case 'up':
      return `rotate(-90deg)`
    case 'right':
    default:
      return 'none'
  }
}

export const ArrowLong: FC<Props> = ({
  color = 'white',
  direction = Direction.right,
  customHeight = '20px',
  ...rest
}) => {
  return (
    <Box
      as="svg"
      {...rest}
      // @ts-ignore
      width="75"
      height={customHeight}
      viewBox="0 0 75 20"
      fill="none"
      sx={{
        transform: rotate(direction),
      }}
    >
      <path
        opacity="0.85"
        d="M71.8839 10.8839C72.372 10.3957 72.372 9.60427 71.8839 9.11612L63.9289 1.16117C63.4408 0.67301 62.6493 0.67301 62.1612 1.16117C61.673 1.64932 61.673 2.44078 62.1612 2.92893L69.2322 10L62.1612 17.0711C61.673 17.5592 61.673 18.3507 62.1612 18.8388C62.6493 19.327 63.4408 19.327 63.9289 18.8388L71.8839 10.8839ZM0 11.25L71 11.25V8.75L0 8.75L0 11.25Z"
        fill={color}
      />
    </Box>
  )
}
