import React from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '~/styled/global'

import CustomerProvider from '~/context/customer-context'
import CartProvider from '~/context/cart-context'
import { OverlayProvider } from '~/context/overlay-context'
import MenuProvider from '~/context/menu-context'

import StyledTheme from '~/styled/theme'
import BreadcrumbProvider from '~/context/breadcrumb'
import RecentlyViewedProductsProvider from '~/context/products'
import { IdentifierModalProvider } from '~/context/identifier-modal'
import { DashboardContentProvider } from '../account-layout/context/dashboardContext'
import ExitIntentPopup from '~/componentsV2/exitIntentPopup'
import { ThemeUIProvider, Theme } from 'theme-ui'
import { theme } from '~/gatsby-plugin-theme-ui'

interface IProps {
  children: React.ReactNode
}

const RootElement = ({ children }: IProps) => {
  const isSSR = typeof window === 'undefined'

  return (
    <ThemeUIProvider theme={theme as Theme}>
      <ThemeProvider theme={StyledTheme}>
        <CustomerProvider>
          <CartProvider>
            <GlobalStyle />
            <ExitIntentPopup />
            <MenuProvider>
              <BreadcrumbProvider>
                <RecentlyViewedProductsProvider>
                  <IdentifierModalProvider>
                    <DashboardContentProvider>
                      <OverlayProvider>{children}</OverlayProvider>
                    </DashboardContentProvider>
                  </IdentifierModalProvider>
                </RecentlyViewedProductsProvider>
              </BreadcrumbProvider>
            </MenuProvider>
          </CartProvider>
        </CustomerProvider>
      </ThemeProvider>
    </ThemeUIProvider>
  )
}

export default RootElement
