import React, { createContext, useContext, useEffect, useState } from 'react'

import { getBreadcrumb } from '~/graphql/getters'
import { BreadcrumbResult } from '~/graphql/types'

type BreadrcrumbItem = {
  title: string
  handle: string
  type: string
}

export type BreadcrumbContextType = {
  breadcrumb: ParsedBreadcrumb
  collections: {
    [key: string]: string
  }
  collectionsSub: {
    [key: string]: BreadrcrumbItem
  }
}

export type ParsedBreadcrumb = BreadrcrumbItem[]

const parseBreadcrumbData = (data: BreadcrumbResult): BreadcrumbContextType | undefined => {
  const { breadcrumb } = data
  const parsedBreadcrumb: ParsedBreadcrumb = []
  const collections:
    | {
        [key: string]: string
      }
    | undefined = {}
  const collectionsSub:
    | {
        [key: string]: {
          title: string
          handle: string
          type: string
        }
      }
    | undefined = {}

  if (breadcrumb && breadcrumb.fields && breadcrumb.fields.length > 0) {
    const nodes = breadcrumb.fields[0].references?.nodes || []

    nodes.forEach(node => {
      const { type, fields } = node

      fields.forEach(field => {
        if (field.type === 'single_line_text_field') {
          collections[type] = field.value
        }

        field.references?.nodes.map(node => {
          const { title, handle } = node

          if (title && handle) {
            if (!collectionsSub[handle]) {
              collectionsSub[handle] = {
                title,
                handle,
                type,
              }
            }
            parsedBreadcrumb.push({
              title,
              handle,
              type,
            })
          }
        })
      })
    })

    return {
      breadcrumb: parsedBreadcrumb,
      collections,
      collectionsSub,
    }
  }
}

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumb: [],
  collections: {},
  collectionsSub: {},
})

export const useBreadcrumbState = () => useContext(BreadcrumbContext)

const BreadcrumbProvider: React.FC = ({ children }) => {
  const [breadcrumb, setBreadcrumb] = useState<ParsedBreadcrumb>([])
  const [collections, setCollections] = useState<{}>({})
  const [collectionsSub, setCollectionsSub] = useState<{}>({})

  useEffect(() => {
    Promise.resolve(getBreadcrumb()).then(res => {
      const parsed = parseBreadcrumbData(res)

      if (parsed) {
        setBreadcrumb(parsed.breadcrumb)
        setCollections(parsed.collections)
        setCollectionsSub(parsed.collectionsSub)
      }
    })
  }, [])

  return (
    <BreadcrumbContext.Provider value={{ breadcrumb, collections, collectionsSub }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export default BreadcrumbProvider
