import qs from 'qs'
import axios from 'axios'
import { env } from '~/common/env'
import { SwymGenerateRegId, SwymList, SwymSFL } from '~/components/account-layout/hooks/types'

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Accept: 'application/json',
}

const oldAgent = 'webApp'
const userAgent = 'mobileApp'

const headers2 = {
  ...headers,
  'user-agent': 'webApp',
}

export const axiosRequests = () => {
  const genRegId = async (userEmail: string) => {
    const data = qs.stringify({
      useragenttype: 'webApp',
      useremail: userEmail,
    })
    const configReg = {
      method: 'post',
      url: `${env.swym_url}/storeadmin/v3/user/generate-regid`,
      data: data,
    }
    return (await axios(configReg)) as {
      data: SwymGenerateRegId
    }
  }

  const getSwymlists = async (sessionid: string, regid: string, sfl?: boolean) => {
    const pid = encodeURIComponent(env.swym_pid)
    let dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      // useragenttype: 'webApp',
    })

    if (sfl) {
      dataLists = qs.stringify({
        sessionid: sessionid,
        regid: regid,
        'user-agent': oldAgent,
      })
    }

    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/${sfl ? 'sfl/fetch' : 'fetch-lists'}?pid=${pid}`,
      headers,
      data: dataLists,
    }
    return (await axios(configList)) as {
      data: SwymList[]
    }
  }

  const createSwymSfl = async (sessionid: string, regid: string) => {
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      lname: 'sfl',
      sessionid: sessionid,
      regid: regid,
      'user-agent': oldAgent,
    })

    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/sfl/create?pid=${pid}`,
      headers,
      data: dataLists,
    }
    return await axios(configList)
  }

  const createWishlists = async (listName: string, Response: any) => {
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      lname: listName,
      sessionid: Response.data.sessionid,
      regid: Response.data.regid,
    })
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/create?pid=${pid}`,
      headers,
      data: dataLists,
    }
    return await axios(configList)
  }

  const removeWishlist = async (sessionid: string, regid: string, id: string) => {
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: id,
    })
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/delete-list?pid=${pid}`,
      headers: headers2,
      data: dataLists,
    }
    return await axios(configList)
  }

  const getListProducts = async (sessionid: string, regid: string, id: string) => {
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      lid: id,
      sessionid: sessionid,
      regid: regid,
    })
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/fetch-list-with-contents?pid=${pid}`,
      headers,
      data: dataLists,
    }
    return await axios(configList)
  }

  const addToWishlist = async (
    productVariantId: any,
    shopifyId: any,
    sessionid: string,
    regid: string,
    lid: string,
    handle: any
  ) => {
    const prefix = `https://bensoleimani.myshopify.com/products/${handle}`
    const productData = {
      epi: Number(productVariantId),
      empi: Number(shopifyId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: lid,
      a: jsonString,
    })
    // console.log(productData);
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/update-ctx?pid=${pid}`,
      headers: headers2,
      data: dataLists,
    }
    return await axios(configList)
  }

  const addToSfl = async (
    productVariantId: any,
    shopifyId: any,
    sessionid: string,
    regid: string,
    lid: string,
    handle: any
  ) => {
    const prefix = `https://bensoleimani.myshopify.com/products/${handle}`
    const productData = {
      epi: Number(productVariantId),
      empi: Number(shopifyId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: lid,
      items: jsonString,
      'user-agent': oldAgent,
    })
    // console.log(productData);
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/sfl/add?pid=${pid}`,
      headers: headers2,
      data: dataLists,
    }
    return await axios(configList)
  }

  const removeFromWishlist = async (
    productVariantId: any,
    shopifyId: any,
    sessionid: string,
    regid: string,
    lid: string,
    handle: any
  ) => {
    const prefix = `https://bensoleimani.myshopify.com/products/${handle}`
    const productData = {
      epi: Number(productVariantId),
      empi: Number(shopifyId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: lid,
      d: jsonString,
    })
    // console.log(productData);
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/update-ctx?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataLists,
    }
    return await axios(configList)
  }

  const removeFromSfl = async (
    productVariantId: any,
    shopifyId: any,
    sessionid: string,
    regid: string,
    lid: string,
    handle: any
  ) => {
    const prefix = `https://bensoleimani.myshopify.com/products/${handle}`
    const productData = {
      epi: Number(productVariantId),
      empi: Number(shopifyId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: lid,
      items: jsonString,
      'user-agent': oldAgent,
    })
    // console.log(productData);
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/sfl/remove?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataLists,
    }
    return await axios(configList)
  }

  const copyToWishlist = async (
    listId: string,
    listName: string,
    productId: any,
    sessionid: string,
    regid: string,
    variantId: any,
    productHandle: string
  ) => {
    const prefix = productHandle
    const productData = {
      epi: Number(variantId),
      empi: Number(productId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: listId,
      a: jsonString,
    })
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/update-ctx?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataLists,
    }
    return await axios(configList)
  }

  const moveToWishlist = async (
    listId: string,
    listName: string,
    productId: any,
    sessionid: string,
    regid: string,
    variantId: any,
    productHandle: string,
    fromList: number
  ) => {
    const prefix = `https://bensoleimani.myshopify.com${productHandle}`
    const productData = {
      epi: Number(variantId),
      empi: Number(productId),
      du: prefix.toString(),
    }
    const arrayOfProductData = [productData]
    const jsonString = JSON.stringify(arrayOfProductData)
    const pid = encodeURIComponent(env.swym_pid)
    const dataListsAdd = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: listId,
      a: jsonString,
    })
    const dataListsRemove = qs.stringify({
      sessionid: sessionid,
      regid: regid,
      lid: fromList,
      d: jsonString,
    })
    const configListAdd = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/update-ctx?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataListsAdd,
    }
    const configListRemove = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/update-ctx?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataListsRemove,
    }
    await axios(configListAdd)
      .then(function (response) {
        return axios(configListRemove)
      })
      .catch(function (error) {
        return error
      })
  }

  const getList = async (id, sessionid, regid) => {
    const pid = encodeURIComponent(env.swym_pid)
    const dataLists = qs.stringify({
      lid: id.slice(1),
      sessionid: sessionid,
      regid: regid,
    })
    const configList = {
      method: 'post',
      url: `${env.swym_url}/api/v3/lists/fetch-user-list?pid=${pid}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      data: dataLists,
    }
    return await axios(configList)
  }

  return {
    genRegId,
    getSwymlists,
    createWishlists,
    removeWishlist,
    getListProducts,
    addToWishlist,
    removeFromWishlist,
    copyToWishlist,
    moveToWishlist,
    getList,
    addToSfl,
    removeFromSfl,
    createSwymSfl,
  }
}
