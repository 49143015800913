/* eslint-disable no-console */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React, { createContext, useState, useContext, useEffect } from 'react'
import ReactPinterestTag from 'react-pinterest-tag'
import './src/fonts/stylesheet.css'
import RootElement from './src/components/root-element'
import { setImpactRadiusId, trackIdentifyUser } from './src/components/impact-radius'
import './src/styles/breadcrumb.css'
import './src/styles/global.css'
import EventEmitter from 'events'

import { LoadingText } from './src/componentsV2/loadingText'

export const emitter = new EventEmitter()

const LoadingOverlay = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}
  >
    <p style={{ color: '#000' }}>
      <LoadingText />
    </p>
  </div>
)

const LoadingContext = createContext()

export const useLoading = () => useContext(LoadingContext)

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

const LoadingManager = ({ children }) => {
  const { isLoading, setIsLoading } = useLoading()

  useEffect(() => {
    const showLoading = () => setIsLoading(true)
    const hideLoading = () => setIsLoading(false)

    emitter.on('show-loading', showLoading)
    emitter.on('hide-loading', hideLoading)

    return () => {
      emitter.off('show-loading', showLoading)
      emitter.off('hide-loading', hideLoading)
    }
  }, [])

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {children}
    </>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingProvider>
      <RootElement>
        <LoadingManager>{element}</LoadingManager>
      </RootElement>
    </LoadingProvider>
  )
}

export const onClientEntry = () => {
  setImpactRadiusId()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    const email = localStorage.getItem('email') || ''
    const customerType = localStorage.getItem('customer_type') || 'Unidentified'
    const idPopUp = localStorage.getItem('idPopUp') || 'Unidentified'
    const user = localStorage.getItem('user')
    const userData = user ? JSON.parse(user) : null

    const userEmail = userData?.email || email

    window.hj('identify', userData?.id || null, {
      email: userEmail,
      customer_type:
        userEmail.length > 0
          ? userData?.email?.includes('@bensoleimani')
            ? 'Bensoleimani'
            : customerType
          : 'Unidentified',
      idPopUp,
    })

    if (window?.pintrk) {
      if (!prevLocation) {
        ReactPinterestTag.init(process.env.PINTEREST_TAG_ID)
      }

      ReactPinterestTag.pageView()
      ReactPinterestTag.track('pagevisit')
    }
  }

  const pathname = location.pathname

  if (pathname.startsWith('/collections')) {
    ReactPinterestTag.track('viewcategory')
  }

  trackIdentifyUser()
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const savedPosition = getSavedScrollPosition(location)

  if (!savedPosition) return true
  emitter.emit('show-loading')

  const handleScroll = () => {
    const scrollY = window.scrollY
    setTimeout(() => {
      if (savedPosition[1] !== scrollY) {
        window.scrollTo(...savedPosition)
        handleScroll()
      } else {
        emitter.emit('hide-loading')
        return false
      }
    }, 200)
  }

  handleScroll()

  return false
}

export const registerServiceWorker = () => true

export const onServiceWorkerActive = async () => {
  console.log('Custom Service worker active')
  await caches.keys().then(names => {
    for (const name of names) {
      caches.delete(name)
    }
  })
}

export const onServiceWorkerUpdateFound = async () => {
  console.log('Custom Service worker update found')
  await caches.keys().then(names => {
    for (const name of names) {
      caches.delete(name)
    }
  })
}

export const onServiceWorkerRedundant = async () => {
  console.log('Custom Service worker redundant')
  await caches.keys().then(names => {
    for (const name of names) {
      caches.delete(name)
    }
  })
}

export function onServiceWorkerUpdateReady() {
  window.location.reload(true)
}
