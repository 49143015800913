import { map, pipe, prop } from 'ramda'
import {
  CheckoutLineItem,
  CheckoutType,
  DiscountApplication,
  MoneyType,
  PricingPercentageValue,
} from '~/@types/models'
import * as R from 'ramda'
import { formatMoney, base64DecodeShopifyId } from '~/utils/format'

const mapEdges = pipe(prop('edges'), map(prop('node')))

export const mapCheckout = (response: object): CheckoutType => {
  const lineItems = mapEdges(response.lineItems).map(lineItem => ({
    ...lineItem,
    variant: {
      ...lineItem.variant,
      shopifyId: base64DecodeShopifyId(lineItem.variant.id),
    },
  })) as CheckoutLineItem[]
  const discountApplications = mapEdges(response.discountApplications) as DiscountApplication[]

  return {
    ...response,
    lineItems,
    discountApplications,
  } as CheckoutType
}

export const getDiscountsList = (checkout: CheckoutType) => {
  const discountsList = checkout && checkout.discountApplications

  if (!discountsList) {
    return []
  }

  return discountsList
    .filter(discount => discount.__typename !== 'ScriptDiscountApplication')
    .map(discount => {
      const { value, __typename, code } = discount
      const discountCode = String(code).toUpperCase()

      return {
        label: discountCode && `Discount code ${discountCode}`,
        value:
          __typename === 'PricingPercentageValue'
            ? (value as PricingPercentageValue).percentage + '%'
            : formatMoney((value as MoneyType).amount, (value as MoneyType).currencyCode),
      }
    })
}

export const countQuantity = (items: CheckoutLineItem[]): number => {
  return R.sum(map(prop('quantity'), items))
}

export enum errorCodes {
  INVALID_FOR_COUNTRY = 'INVALID_FOR_COUNTRY',
}

export const shippingStates = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Virgin Islands',
  'Armed Forces Americas',
  'Armed Forces Europe',
  'Armed Forces',
]
