import { client } from './client'
import * as queries from './queries'
import { BreadcrumbResult, MenuImageResult } from './types'

export const getVariants = async (variantIds = []) => {
  const result = await client.request(queries.GET_VARIANT, {
    variantIds,
  })

  return result.variants
}

export const getProduct = async (handle = '') => {
  const result = await client.request(queries.PRODUCT, {
    handle,
  })

  return result.product
}

export const getProductByID = async (id = '') => {
  const result = await client.request(queries.PRODUCT_BY_ID, {
    id,
  })
  const parserProduct = (product: any) => {
    product.collections = product.collections.edges.map((edge: any) => edge.node)

    return product
  }

  return parserProduct(result.product)
}

export const getMenuImages = async (): Promise<MenuImageResult> => {
  const result = await client.request(queries.GET_MENU_IMAGES)

  return result.menu
}

export const getHomepage = async (isPreview?: boolean) => {
  const homeId = isPreview
    ? 'gid://shopify/Metaobject/16430202944'
    : 'gid://shopify/Metaobject/7986872384'
  const result = await client.request(queries.HOMEPAGE, {
    homeId,
  })

  return result.data
}

export const getBreadcrumb = async (): Promise<BreadcrumbResult> => {
  const result = await client.request(queries.BREADCRUMB)

  return result
}

export const getProductByHandle = async (handle = '') => {
  const result = await client.request(queries.PRODUCT_BY_HANDLE, {
    handle,
  })

  const parserProduct = (product: any) => {
    product.collections = product.collections.edges.map((edge: any) => edge.node)

    return product
  }

  return parserProduct(result.product)
}
