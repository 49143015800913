const Theme = {
  colors: {
    red: '#EF402F',
    grey: '#EFEFEF',
    black: '#2b2b2b',
  },
  mediaQueries: {
    /**
     *  mobile: '(max-width: 576px)'
     */
    mobile: '(max-width: 576px)',

    /**
     *  tablet: '(min-width: 576.1px) and (max-width: 1024px)'
     */
    tablet: '(min-width: 576.1px) and (max-width: 1024px)',

    /**
     *  desktop: '(min-width: 1024.1px)'
     */
    desktop: '(min-width: 1024.1px)',

    /**
     *  sm: '(min-width: 0) and (max-width: 576px)'
     */
    sm: '(min-width: 0) and (max-width: 576px)',

    /**
     *  maxSm: '(max-width: 576px)'
     */
    maxSm: '(max-width: 576px)',

    /**
     *  md: '(min-width: 576.1px) and (max-width: 768px)'
     */
    md: '(min-width: 576.1px) and (max-width: 768px)',

    /**
     *  maxMd: '(max-width: 768px)'
     */
    maxMd: '(max-width: 768px)',

    /**
     *  lg: '(min-width: 768.1px) and (max-width: 1024px)'
     */
    lg: '(min-width: 768.1px) and (max-width: 1024px)',

    /**
     *  maxLg: '(max-width: 1024px)'
     */
    maxLg: '(max-width: 1024px)',

    /**
     *  xl: '(min-width: 1024.1px) and (max-width: 1440px)'
     */
    xl: '(min-width: 1024.1px) and (max-width: 1440px)',

    /**
     *  maxXl: '(max-width: 1440px)'
     */
    maxXl: '(max-width: 1440px)',

    /**
     *  xxl: '(min-width: 1440.1px) and (max-width: 1920px)'
     */
    xxl: '(min-width: 1440.1px) and (max-width: 1920px)',

    /**
     *  maxXxl: '(max-width: 1920px)'
     */
    maxXxl: '(max-width: 1920px)',
  },
  minBreakPoints: {
    mobile: 0,
    tablet: 576,
    desktop: 1024,
    lDesktop: 1440,
  },
  maxBreakPoints: {
    mobile: 576,
    tablet: 1024,
    desktop: 1440,
  },
}
export default Theme
