const GraphQLClient = require('graphql-request').GraphQLClient

const createClient = (store, accessToken, isAdmin = false, version = '2022-10') => {
  const CLIENT_OPTIONS = {
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      [`${isAdmin ? 'X-Shopify-Access-Token' : 'X-Shopify-Storefront-Access-Token'}`]: accessToken,
    },
  }

  const URL = `https://${store}.myshopify.com/${
    isAdmin ? '/admin' : ''
  }/api/${version}/graphql.json`

  return {
    request: async (query, variables) => {
      try {
        const client = new GraphQLClient(URL, CLIENT_OPTIONS)
        const response = await client.request(query, variables)
        return response
      } catch (error) {
        // Handle the error or log it as needed
        console.error(`GraphQL request failed on attempt`, error)
      }
    },
  }
}

module.exports = createClient
