import React, { createContext, useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { getMenuImages } from '~/graphql/getters'
import { NavLinkType } from '~/@types/models'
import { MenuImageResult } from '~/graphql/types'
import { IMedia } from '~/componentsV2/ResposiveImage'
import { cmsEnv } from '~/common/cms'

const menuQuery = graphql`
  query menuQuery2 {
    menu: settingsJson(templateKey: { eq: "menu-v2" }) {
      list {
        name
        visible
        link
        target
        divider
        customStyles
        submenu {
          name
          link
          target
          divider
          type
          imgSrc
          imageSlot
          submenu {
            name
            link
            target
            divider
            bottomLevel
            submenu {
              name
              link
              bottomLevel
            }
          }
        }
      }
    }
    menuByCmsNew {
      menus {
        name
        showMobileFooter
        reverseGroups
        menus {
          id
          groupName
          mobileImage {
            id
            placeholder
            url
            width
            height
            alternativeText
            formats
          }
          menus {
            id
            name
            link
            mobileImage {
              id
              placeholder
              url
              width
              height
              alternativeText
              formats
            }
            menus {
              mobileImage {
                id
                placeholder
                url
                width
                height
                alternativeText
                formats
              }
              id
              name
              link
            }
          }
        }
        images {
          id
          link
          desktopImage {
            id
            placeholder
            url
            width
            height
            alternativeText
            formats
          }
          mobileImage {
            id
            placeholder
            url
            width
            height
            alternativeText
            formats
          }
          name
        }
        mobileImage {
          alternativeText
          formats
          height
          placeholder
          width
          url
          id
        }
        id
        link
      }
      menusMobile {
        id
        name
        link
        mobileImage {
          id
          placeholder
          url
          width
          height
          alternativeText
          formats
        }
      }
      topMenuLeft {
        id
        name
        link
        mobileImage {
          id
          placeholder
          url
          width
          height
          alternativeText
          formats
        }
      }
      id
    }
  }
`

export interface IMenuItem {
  id: number
  name: string
  link?: string
  mobileImage?: IMedia
  menus: IMenuItem[]
}

export interface IMenuGroup {
  id: number
  groupName: string
  link: string
  mobileImage?: IMedia
  menus: IMenuItem[]
}

export interface IMenuImageGroup {
  id: number
  name: string
  link: string
  desktopImage: IMedia
  mobileImage: IMedia
}

export interface IMenu {
  id: number
  name: string
  link: string
  reverseGroups: boolean
  showMobileFooter: boolean
  mobileImage?: IMedia
  menus: IMenuGroup[]
  images: IMenuImageGroup[]
}

export interface INewMenu {
  topMenuLeft: {
    id: number
    name: string
    link: string
    mobileImage?: IMedia
  }
  menus: IMenu[]
  menusMobile: IMenu[]
}

interface QueryResult {
  menu: {
    list: NavLinkType[]
  }
  menuByCmsNew: INewMenu
}

type MenuContextType = {
  menu: NavLinkType[]
  menuImages: MenuImageResult | null
  menuImagesParsed: MenuImageParsed
  dynamicMenu: INewMenu
}

interface MenuImageParsed {
  [key: string]: string
}

const MenuContext = createContext<MenuContextType>({
  menu: [],
  dynamicMenu: {} as INewMenu,
  menuImages: null,
  menuImagesParsed: {},
})

export const useMenu = () => useContext(MenuContext)

// eslint-disable-next-line @typescript-eslint/promise-function-async, @typescript-eslint/no-explicit-any
export const loadDraftMenu = async (): Promise<INewMenu> => {
  const res = await fetch(`${cmsEnv.endpoint}/menu?draft=true`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${cmsEnv.token}`,
    },
  })
  const { data } = await res.json()

  return data
}

const MenuProvider: React.FC = ({ children }) => {
  const [menu, setMenu] = useState<NavLinkType[]>([])
  const [menuImages, setMenuImages] = useState<MenuImageResult | null>(null)
  const [menuImagesParsed, setMenuImagesParsed] = useState<MenuImageParsed>({})
  const [dynamicMenu, setDynamicMenu] = useState<INewMenu>({} as INewMenu)

  const {
    menu: { list },
    menuByCmsNew,
  } = useStaticQuery<QueryResult>(menuQuery)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const draft = window.location.search.includes('draft=true')

      if (draft) {
        try {
          loadDraftMenu().then(data => {
            setDynamicMenu(data)
          })
        } catch (e) {
          setDynamicMenu({} as INewMenu)
        }
      }
    }
  }, [])

  useEffect(() => {
    getMenuImages().then(res => setMenuImages(res))

    const filtered = list.filter(item => item.visible)

    setMenu(filtered)
  }, [list])

  useEffect(() => {
    if (!menuImages) return

    const parsed = menuImages.fields.reduce((acc, { key, reference }) => {
      acc[key] = reference.image.url

      return acc
    }, {} as MenuImageParsed)

    setMenuImagesParsed(parsed)
  }, [menuImages])

  return (
    <MenuContext.Provider
      value={{
        menu,
        dynamicMenu: dynamicMenu?.menus?.length ? dynamicMenu : menuByCmsNew,
        menuImages,
        menuImagesParsed,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
