/* eslint-disable react/no-danger */
import React, { FC, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import urls from '~/common/urls'
import { CheckoutLineItem, SiteMetaDataType, CheckoutType } from '~/@types/models'

type QueryResult = {
  site: {
    siteMetadata: Pick<SiteMetaDataType, 'title' | 'siteUrl'>
  }
}

const query = graphql`
  query AddedToCartSnippet {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

type Props = {
  items: CheckoutLineItem[]
  checkout: CheckoutType
  recentItem: CheckoutLineItem
  newItemBool: boolean
  setNewItemBool: any
}

const AddToCartSnippet: FC<Props> = ({
  items,
  checkout,
  recentItem,
  newItemBool,
  setNewItemBool,
}) => {
  const {
    site: {
      siteMetadata: { title, siteUrl },
    },
  } = useStaticQuery<QueryResult>(query)
  //added product
  const productsInCart = useMemo(() => {
    return items?.map(item => {
      return {
        ProductID: `${item?.id}`,
        SKU: `${item?.variant?.sku}`,
        ProductName: `${item?.title}`,
        Quantity: `${item?.quantity}`,
        ItemPrice: `${item?.variant.price.amount}`,
        RowTotal: `${Number(item.quantity) * Number(item.variant.price.amount)}`,
        ProductURL: `${siteUrl}${urls.pages.productDetail}/${item.variant.product?.handle}`,
        ImageURL: `${item?.variant?.image?.originalSrc}`,
        ProductCategories: [`${item?.variant?.product?.productType}`],
      }
    })
  }, [items, siteUrl])

  const itemNames = useMemo(() => {
    return items.map(item => {
      return item.title
    })
  }, [items])
  if (Object.keys(recentItem).length === 0) {
    recentItem = items[0]
  }
  const addToCart = (_learnq: any) => {
    const item = {
      $value: checkout?.subtotalPrice?.amount,
      AddedItemProductName: recentItem?.title,
      AddedItemProductID: recentItem?.id,
      AddedItemSKU: recentItem?.variant?.sku,
      AddedItemCategories: [recentItem?.variant?.product?.productType],
      AddedItemImageURL: recentItem?.variant?.image?.originalSrc,
      AddedItemURL: `${siteUrl}${urls.pages.productDetail}/${recentItem?.variant?.product?.handle}`,
      AddedItemPrice: recentItem?.variant?.price?.amount,
      AddedItemQuantity: recentItem?.quantity,
      ItemNames: itemNames,
      CheckoutURL: checkout?.webUrl,
      Items: productsInCart,
    }
    _learnq.push(['track', 'Added to Cart', item])
  }
  // items mapped
  if (newItemBool && typeof _learnq !== 'undefined') {
    setNewItemBool(false)
    addToCart(_learnq)
  }
  return <> </>
}

export default AddToCartSnippet
