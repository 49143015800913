const getArray = (data: any) => data || []

const isProduction = process.env.NODE_ENV === 'production'

const mapPositionToFlex = (position: 'left' | 'center' | 'right') => {
  switch (position) {
    case 'right':
      return 'flex-end'
    case 'center':
      return 'center'
    case 'left':
    default:
      return 'flex-start'
  }
}

export { getArray, isProduction, mapPositionToFlex }
