import { Link } from 'gatsby'
import React, { createContext, useState, useEffect, Fragment, useContext, useMemo } from 'react'
import { Box, Text, Button } from 'theme-ui'
import { Close } from '~/components/icons/close'
// eslint-disable-next-line import/no-cycle
import { useCustomerContext } from './customer-context'
import { CustomerTitleSegment } from './type'

const initialValue = {
  isModalOpen: false,
  customerSegment: '',
}
const IdentifierModalContext = createContext(initialValue)

/**
 * Provides a modal context for the application.
 *
 * @param {React.ReactNode} children - The children components to be rendered within the modal context.
 * @return {JSX.Element} The JSX element containing the modal context provider.
 */
const IdentifierModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentTitle, setCurrentTitle] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('idPopUp') || '' : ''
  )
  const { isLoggedIn } = useCustomerContext()

  const customerSegment = useMemo(() => {
    if (currentTitle === CustomerTitleSegment.RETAIL) {
      return 'retail'
    }
    return 'trade'
  }, [currentTitle])

  useEffect(() => {
    const isPopUpSeen = localStorage.getItem('idPopUp')
    if (!isPopUpSeen) {
      setIsModalOpen(true)
    }
  }, [])

  const handleCloseModal = (title: string) => {
    setCurrentTitle(title)
    localStorage.setItem('idPopUp', title)

    if (title === CustomerTitleSegment.RETAIL || isLoggedIn) {
      setIsModalOpen(false)
    }

    return null
  }

  const content = [
    {
      title: CustomerTitleSegment.INTERIOR,
      description: 'Design Professional',
    },
    {
      title: CustomerTitleSegment.COMMERCIAL,
      description: 'Design Professional',
    },
    {
      title: CustomerTitleSegment.RETAIL,
      description: 'Shopping for Myself',
    },
  ]

  const ActionButton = ({ title, description }: { title: string; description: string }) => {
    return (
      <Button
        sx={{
          aspectRatio: ['16/6', '1/1'],
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          background: 'rgb(239, 239, 239) !important',
          color: '#16283E',
          transition: 'all 0.3s ease',
          borderRadius: '0',
          '&:hover': {
            boxShadow: '0 0 0 1px #16283E',
          },
        }}
        onClick={() => handleCloseModal(title)}
      >
        <Text
          as="h2"
          sx={{
            fontSize: ['24px', '22px', '28px'],
            fontFamily: 'Cormorant Garamond',
            color: '#16283E',
            fontWeight: 400,
          }}
        >
          {title}
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: ['22px', '16px', '26px'],
            fontFamily: 'Cormorant Garamond',
            color: '#16283E',
            fontWeight: 400,
            fontStyle: 'italic',
          }}
        >
          {description}
        </Text>
      </Button>
    )
  }

  return (
    <IdentifierModalContext.Provider value={{ isModalOpen, customerSegment }}>
      {children}
      {isModalOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: '90%',
              marginLeft: [0, null, '-5%'],
              maxWidth: '900px',
              padding: ['30px', '40px'],
              backgroundColor: 'white',
              borderRadius: '0',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            {currentTitle.length && currentTitle !== 'RETAIL' ? (
              <Button
                onClick={() => setIsModalOpen(false)}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  cursor: 'pointer',
                  color: '#16283E',
                  backgroundColor: 'transparent',
                }}
              >
                <Close />
              </Button>
            ) : null}
            {currentTitle.length && currentTitle !== 'RETAIL' ? (
              <Box>
                <Text
                  as="p"
                  sx={{
                    fontSize: ['22px', '22px', '28px'],
                    fontFamily: 'Cormorant Garamond',
                    color: '#16283E',
                    fontWeight: 400,
                    margin: '20px 0',
                  }}
                >
                  We’ve Increased Our Trade Discount! |{' '}
                  <Link
                    to="/sign-in"
                    style={{
                      color: '#16283E',
                      textDecoration: 'underline',
                      letterSpacing: '0.5px',
                    }}
                    onClick={() => setIsModalOpen(false)}
                  >
                    Login to View
                  </Link>
                </Text>
              </Box>
            ) : (
              <Fragment>
                <Text
                  as="h1"
                  sx={{
                    fontSize: '32px',
                    fontFamily: 'Cormorant Garamond',
                    color: '#16283E',
                    fontWeight: 400,
                  }}
                >
                  WELCOME!
                </Text>
                <Text
                  as="p"
                  sx={{
                    fontSize: ['22px', '22px', '28px'],
                    fontFamily: 'Cormorant Garamond',
                    color: '#16283E',
                    fontWeight: 400,
                  }}
                >
                  To Better Assist You, Please Tell Us About Yourself. I am a....
                </Text>
                <Box
                  sx={{
                    display: 'grid',
                    justifyContent: 'space-around',
                    marginTop: '20px',
                    gap: '20px',
                    // [0,895,1199]
                    gridTemplateColumns: ['1fr', 'repeat(3, minmax(100px, 232px))'],
                  }}
                >
                  {content.map((item, index) => (
                    <ActionButton key={index} title={item.title} description={item.description} />
                  ))}
                </Box>
              </Fragment>
            )}
          </Box>
        </Box>
      )}
    </IdentifierModalContext.Provider>
  )
}

export const useIdentifierModal = () => {
  const context = useContext(IdentifierModalContext)
  if (context === undefined) {
    throw new Error('useIdentifierModal must be used within a IdentifierModalProvider')
  }
  return context
}

export { IdentifierModalProvider, IdentifierModalContext }
