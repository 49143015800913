/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { FC, SVGProps } from 'react'

enum Direction {
  'up' = 'up',
  'down' = 'down',
  'left' = 'left',
  'right' = 'right',
}

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: Direction
}

const rotate = (direction: Direction) => {
  switch (direction) {
    case 'down':
      return `rotate(90deg)`
    case 'left':
      return `rotateY(180deg)`
    case 'up':
      return `rotate(-90deg)`
    case 'right':
    default:
      return 'none'
  }
}

export const Share: FC<Props> = ({
  color = 'currentColor',
  direction = Direction.left,
  width,
  height,
  ...rest
}) => {
  return (
    <Box
      as="svg"
      {...rest}
      // @ts-ignore
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      sx={{
        transform: rotate(direction),
      }}
    >
      <path
        d="M11 20L1 12L11 4V9C16.5228 9 21 13.4772 21 19C21 19.2727 20.9891 19.5428 20.9677 19.81C19.5055 17.0364 16.6381 15.119 13.313 15.0053L13 15H10.9999L11 20ZM8.99986 13H10.9999L13.0341 13.0003L13.3814 13.0065C14.6657 13.0504 15.9053 13.3165 17.0568 13.7734C15.5898 12.0749 13.4204 11 11 11H9V8.16125L4.20156 12L8.99992 15.8387L8.99986 13Z"
        fill={color}
      />
    </Box>
  )
}
